
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useModalEffect from '../../../hooks/useModalEffect.js';
import useWorkplaceController from '../../../controllers/useWorkplaceController.js';
import useCountryController from '../../../controllers/useCountryController.js';

const WorkplaceDetail = ({ id, workplace: theWorkplace, onSave }) => {

    /**
     * Page state
     */

    const modalRef = useRef();
    const { t } = useTranslation();
    const [workplace, setWorkplace] = useState({});
    const [countries, setCountries] = useState([]);
    const [deletable, setDeletable] = useState(false);
    
    const workplaceController = useWorkplaceController();
    const countryController = useCountryController();
    
    /**
     * Page lifecycle
    */
   
    useModalEffect(modalRef, async () => {

        setWorkplace(theWorkplace);

        const countries = await countryController.getCountries();
        setCountries(countries.items);

        const deletable = await workplaceController.deletable(theWorkplace);
        setDeletable(deletable);
    })

    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setWorkplace({ ...workplace, ...attributes });
    }

    const handleCountryChange = (e) => {
        const country = countries.find(c => c.id === e.target.value);
        setWorkplace({ ...workplace, country });
    }

    return (

        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.workplace')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>
                        <div>

                            <div className='row pt-2'>

                                <div className='col-4 form-group'>
                                    <label htmlFor='code'>{t('workplaces.code')}</label>
                                    <input id='code' type='text' className='form-control' value={workplace.code ?? ''} placeholder={t('workplaces.code-placeholder')} onChange={(e) => handleChange({ code: e.target.value })} />
                                </div>

                                <div className='col form-group'>
                                    <label htmlFor='name'>{t('workplaces.name')}</label>
                                    <input id='name' type='text' className='form-control' value={workplace.name ?? ''} placeholder={t('workplaces.name-placeholder')} onChange={(e) => handleChange({ name: e.target.value })} />
                                </div>

                            </div>

                            <div className='row pt-2'>

                                <div className='col-9 form-group'>
                                    <label htmlFor='street'>{t('workplaces.street')}</label>
                                    <input id='street' type='text' className='form-control' value={workplace.street ?? ''} placeholder={t('workplaces.street-placeholder')} onChange={(e) => handleChange({ street: e.target.value })} />
                                </div>

                                <div className='col form-group'>
                                    <label htmlFor='streetNumber'>{t('workplaces.number')}</label>
                                    <input id='streetNumber' type='string' className='form-control' value={workplace.streetNumber ?? ''} placeholder={t('workplaces.number-placeholder')} onChange={(e) => handleChange({ streetNumber: e.target.value })} />
                                </div>

                            </div>

                            <div className='row pt-2'>

                                <div className='col form-group'>
                                    <label htmlFor='complement'>{t('workplaces.complement')}</label>
                                    <input id='complement' type='text' className='form-control' value={workplace.streetComplement ?? ''} placeholder={t('workplaces.complement-placeholder')} onChange={(e) => handleChange({ streetComplement: e.target.value })} />
                                </div>

                            </div>

                            <div className='row pt-2'>

                                <div className='col-9 form-group'>
                                    <label htmlFor='city'>{t('workplaces.city')}</label>
                                    <input id='city' type='text' className='form-control' value={workplace.city ?? ''} placeholder={t('workplaces.city-placeholder')} onChange={(e) => handleChange({ city: e.target.value })} />
                                </div>

                                <div className='col form-group'>
                                    <label htmlFor='cityCode'>{t('workplaces.citycode')}</label>
                                    <input id='cityCode' type='string' className='form-control' value={workplace.cityCode ?? ''} placeholder={t('workplaces.citycode-code')} onChange={(e) => handleChange({ cityCode: e.target.value })} />
                                </div>

                            </div>

                            <div className='row pt-2'>

                                <div className='col form-group'>
                                    <label htmlFor='country'>{t('glossary.country')}</label>
                                    <select id='country' className='form-select' value={workplace.country?.id ?? ''} onChange={handleCountryChange}>
                                        <option value={null}>{t('glossary.none')}</option>
                                        {countries.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                    </select>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>

                            <div>
                                <button type='button' className='btn btn-light' data-bs-dismiss='modal' data-bs-toggle='modal' data-bs-target='#delete' disabled={!deletable}>{t('button.delete')}</button>
                            </div>

                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => onSave(workplace)}>{t('button.save')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default WorkplaceDetail;
