
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useExperienceController from '../../../controllers/useExperienceController.js';

import AppLogic from '../../../logic/AppLogic.js';
import ReactUtil from '../../../utils/ReactUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import DateUtil from '../../../utils/DateUtil.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import Paginator from '../../../components/filter/Paginator.js';
import ExperienceDetail from './ExperienceDetail.js';
import ExperienceDelete from './ExperienceDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Experiences = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [filter, setFilter] = useState({ offset: 0, limit: AppLogic.pageLimit, sort: [] });
    const [experience, setExperience] = useState({});
    const [experiences, setExperiences] = useState([]);

    const experienceController = useExperienceController(auth.user, filter);

    const columns = {
        start: { property: 'start', display: 'experiences.start', sortable: false },
        end: { property: 'end', display: 'experiences.end', sortable: false },
        position: { property: 'positionTitle', display: 'experiences.position' },
        company: { property: 'company', display: 'experiences.company' },
        sector: { property: 'sector', display: 'experiences.sector' }
    }

    /**
     * Page lifecycle
     */

    const reload = async (filter) => {
        const data = await experienceController.getExperiences(filter);
        setExperiences(data.items);
        setFilter(data.filter);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [])

    // eslint-disable-next-line 
    useEffect(() => { experienceController.retranslate(experiences); setExperiences([...experiences]); }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const experience = await experienceController.create(auth.user);
        setExperience(experience);
        ReactUtil.show('detail');
    }
    
    const handleDetail = async (id) => {
        const experience = await experienceController.getExperience(id);
        setExperience(experience);
        ReactUtil.show('detail');
    }

    const handleSave = async (experience) => {
        await experienceController.save(experience);
        reload(filter);
    }

    const handleDelete = async (experience) => {
        await experienceController.remove(experience);
        reload(filter);
    }

    const handleFilter = (filter) => {
        reload(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!experienceController.loaded} />
            <ExperienceDetail id='detail' experience={experience} onSave={handleSave} />
            <ExperienceDelete id='delete' experience={experience} onDelete={handleDelete} />

            <div className='row p-4'>

                <TitleBar title={t('profile.experiences')} filter={filter} onChange={handleFilter} />

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>

                            <TableHeader descriptor={columns.start} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.end} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.position} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.sector} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.company} filter={filter} onChange={handleFilter} /> 

                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            experiences.map((experience) => (
                                <tr key={experience.id} className='align-middle'>

                                    <td>{DateUtil.toLocaleString(experience.start) ?? '-'}</td>
                                    <td>{DateUtil.toLocaleString(experience.end) ?? '-'}</td>
                                    <td>{experience.positionTitle ?? '-'}</td>
                                    <td>{experience.sector?.name ?? '-'}</td>
                                    <td>{experience.company ?? '-'}</td>
                                    
                                    <td className='text-center'>
                                        <button className='btn btn-light' onClick={() => handleDetail(experience.id)}>
                                            <img src={edit_img} alt='edit' />
                                        </button>
                                    </td>
                                    
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default Experiences
