
import useExperienceController from './useExperienceController.js';
import useFormationController from './useFormationController.js';
import LangUtil from "../utils/LangUtil.js";
import CurriculumService from '../services/CurriculumService.js';

const useCurriculumController = () => {

    const experienceController = useExperienceController();
    const formationController = useFormationController();

    const getPrivateCurriculum = async (userId, lang) => {

        const data = await CurriculumService.getPrivateCurriculum(userId);

        LangUtil.translateTree(data.catalog, lang);
        data.experiences.forEach(e => experienceController.translate(e, lang));
        data.formations.forEach(e => formationController.translate(e, lang));

        return data;
    }

    const getPublicCurriculum = async (userId, lang) => {

        const data = await CurriculumService.getPublicCurriculum(userId);

        LangUtil.translateTree(data.catalog, lang);
        data.experiences.forEach(e => experienceController.translate(e, lang));
        data.formations.forEach(e => formationController.translate(e, lang));

        return data;
    }

    const getMotionCurriculum = async (userId, motionId = null, lang) => {

        const data = await CurriculumService.getMotionCurriculum(userId, motionId);

        LangUtil.translateTree(data.catalog, lang);
        LangUtil.translateTree(data.motionCatalog, lang);

        data.experiences.forEach(e => experienceController.translate(e, lang));
        data.formations.forEach(e => formationController.translate(e, lang));

        return data;
    }

    return {
        getPrivateCurriculum,
        getPublicCurriculum,
        getMotionCurriculum
    }
}

export default useCurriculumController;