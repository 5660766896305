
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/wishes`;

const WishService = () => {

    const getWishes = async (filter) => {

        let query = filter ? '?' : '';

        if (filter) {
            const sort = filter.sort.map(item => item.name + ':' + item.sort).join(',');
            query += `offset=${filter.offset ?? ''}&limit=${filter.limit ?? ''}&text=${filter.text ?? ''}&sort=${sort}`;
        }

        return (await axios.get(`${SERVER_URL}${query}`)).data.payload;
    }

    const getWishTypes = async () => {
        return (await axios.get(`${SERVER_URL}/types`)).data.payload;
    }

    const getWish = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const create = async () => {
        return (await axios.get(`${SERVER_URL}/create`)).data.payload;
    }

    const isDeletable = async (wish) => {
        return (await axios.get(`${SERVER_URL}/${wish.id}/deletable`)).data.payload;
    }

    const save = async (wish) => {

        return wish.id
            ? (await axios.put(`${SERVER_URL}/${wish.id}`, wish)).data.payload
            : (await axios.post(`${SERVER_URL}`, wish)).data.payload;
    }

    const remove = async (wish) => {
        await axios.delete(`${SERVER_URL}/${wish.id}`);
    }

    return {
        getWishes,
        getWishTypes,
        getWish,
        create,
        isDeletable,
        save,
        remove
    }
}

export default WishService();