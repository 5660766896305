
import { useTranslation } from 'react-i18next';
import AvatarEdit from '../../../components/editor/ImageEditor.js';
import avatar_img from '../../../assets/images/avatar.svg';

import './TabLogin.css';

const TabLogin = ({ user, onChange }) => {

    /**
     * Page state
    */

    const { t } = useTranslation();
    
    /**
     * Event handlers
     */

    const handleAttributeChange = (attr) => {
        onChange(attr);
    }

    return (

        <div className='container'>

            <AvatarEdit id='edit' picture={user.picture} onChange={(e) => handleAttributeChange({ picture: e })} />

            <div className='row pt-3'>

                <div className='col'>

                    <div className='form-group'>
                        <label htmlFor='email'>{t('users.email')}</label>
                        <input id='email' type='text' className='form-control' value={user.email ?? ''} placeholder={t('users.email-placeholder')} autoComplete='off' onChange={(e) => handleAttributeChange({ email: e.target.value })} />
                    </div>

                    <div className='form-group pt-2'>
                        <label htmlFor='password'>{t('users.password')}</label>
                        <input id='password' type='password' className='form-control' value={user.password ?? ''} placeholder={t('users.password-placeholder')} autoComplete='off' onChange={(e) => handleAttributeChange({ password: e.target.value })} />
                    </div>

                    <div className='form-group pt-2'>
                        <label>{t('mycv.link')}</label>
                        <a href={`https://skillspot.app/cv/${user.id}`} className='form-control link'>{`https://skillspot.app/cv/${user.id}`}</a>
                    </div>

                    <div className='form-group pt-2'>
                        <label htmlFor='identifier'>{t('glossary.identifier')}</label>
                        <input id='identifier' type='text' className='form-control text-muted' value={user.id ?? ''} readOnly />
                    </div>

                </div>

                <div className='col form-group d-flex flex-column align-items-center justify-content-center ps-5'>
                    <label>{t('users.avatar')}</label>
                    <div><img className='avatar avatar-editable border' src={user.picture ?? avatar_img} alt='avatar' data-bs-toggle='modal' data-bs-target='#edit'></img></div>
                </div>

            </div>
        </div>

    )
}

export default TabLogin;
