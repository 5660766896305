
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TreeTable from '../treetable/TreeTable.js';
import CatalogLogic from '../../logic/CatalogLogic.js';

import expected_img from '../../assets/images/expected-skill.svg';
import actual_img from '../../assets/images/actual-skill.svg';
import expected_actual_img from '../../assets/images/expected-actual-skill.svg';

import './DeclarativeView.css';

const DeclarativeView = ({ category, showExpectedValues }) => {

    const { t } = useTranslation();

    /**
     * Page state
     */

    const [levels, setLevels] = useState([]);
    const [skilltree, setSkillTree] = useState(category ?? { children: [] });

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            // Get the catalog to display
            const skilltree = { id: 'root', label: t('glossary.skills'), state: { opened: true }, children: category.children }

            // Create the levels array (3 or 5 levels)
            const levels = [...Array((category.levels ?? 3) + 1).keys()].slice(1);

            setLevels(levels);
            setSkillTree(skilltree);

        })()

        // eslint-disable-next-line 
    }, [category]);

    return (

        <div className='container p-0 m-0'>

            <TreeTable className='table table-hover' data={skilltree} onChange={(tree) => setSkillTree(tree)}>
                <thead>
                    <tr className='table-header'>
                        <th>
                            {
                                showExpectedValues &&
                                <span><img src={expected_img} className='skill-icon' alt='expected' /> = {t('mycv.expected-level')}</span>
                            }
                        </th>
                        {
                            showExpectedValues &&
                            <th>{t('mycv.differential-level')}</th>
                        }
                        {
                            category.levels > 3 &&
                            <th>{t('common.levels.theo')}</th>
                        }
                        <th>{t('common.levels.base')}</th>
                        <th>{t('common.levels.autonomous')}</th>
                        <th>{t('common.levels.expert')}</th>
                        {
                            category.levels > 3 &&
                            <th>{t('common.levels.mentor')}</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {

                        (skill) => {

                            const isLeaf = CatalogLogic.isLeaf(skill);
                            const actualLevel = isLeaf && CatalogLogic.getSkillLevel(category, skill);
                            const expectedLevel = isLeaf && CatalogLogic.getSkillExpectedLevel(category, skill);
                            const gap = isLeaf && actualLevel - expectedLevel;

                            return (
                                <tr className='table-row'>
                                    <td className='text-start'>{skill.label}</td>
                                    {
                                        showExpectedValues && isLeaf &&
                                        <td className='text-center'>
                                            {
                                                !skill.level &&
                                                <span className='badge rounded-circle bg-warning'>!</span>
                                            }
                                            {
                                                skill.level && gap < 0 &&
                                                <span className='badge rounded-circle bg-danger'>{Math.abs(gap)}</span>
                                            }
                                            {
                                                skill.level && gap === 0 && <span>-</span>
                                            }
                                            {
                                                skill.level && gap > 0 &&
                                                <span className='badge rounded-circle bg-success'>{Math.abs(gap)}</span>
                                            }
                                        </td>
                                    }
                                    {
                                        showExpectedValues && !isLeaf &&
                                        <td></td>
                                    }
                                    {
                                        levels.map((value) => (
                                            <td key={skill.id + '_' + value}>
                                                {
                                                    showExpectedValues && expectedLevel === value && expectedLevel !== actualLevel &&
                                                    <img src={expected_img} className='skill-icon' alt='expected' />
                                                }
                                                {
                                                    showExpectedValues && actualLevel === value && expectedLevel !== actualLevel &&
                                                    <img src={actual_img} className='skill-icon' alt='actual' />
                                                }
                                                {
                                                    showExpectedValues && actualLevel === value && expectedLevel === actualLevel &&
                                                    <img src={expected_actual_img} className='skill-icon' alt='expected + actual' />
                                                }
                                                {
                                                    !showExpectedValues && actualLevel === value &&
                                                    <img src={actual_img} className='skill-icon' alt='actual' />
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                            )
                        }
                    }
                </tbody>
            </TreeTable>

        </div>
    )
}

export default DeclarativeView
