
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../controllers/useAuthContext.js';
import useAuthenticationController from '../../controllers/useAuthenticationController.js';
import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = ({ roles }) => {

    const { auth } = useAuthContext();
    const controller = useAuthenticationController();

    const navigate = useNavigate();
    const token = sessionStorage.getItem('token');
    const userHasRole = controller.hasRole(roles);

    useEffect(() => {

        // If user is already authenticated, skip autologin
        if (auth.authenticated) return;

        // If user is not authenticated and has no token, got to login
        else if (!token) navigate('/login');

        // Otherwise, try to autologin with the token (asynchronously)
        else {

            (async () => {

                try {
                    await controller.autologin(token);
                }
                catch (e) {
                    console.log(e);
                    navigate('/login');
                }

            })();
        }

        // eslint-disable-next-line 
    }, [auth.authenticated, token])

    // If the user is not authenticated and has no token
    if (!auth.authenticated && !token) {
        return <Navigate to='/login' />;
    }

    // If the user is authenticated, but not authorized
    if (auth.authenticated && !userHasRole) {
        return <Navigate to='/unauthorized' replace />;
    }

    // If the user is authenticated and authorized
    if (auth.authenticated && userHasRole) {
        return <Outlet />;
    }
}

export default PrivateRoutes;
