
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import UserTypeService from '../services/UserTypeService.js';
import LangUtil from "../utils/LangUtil.js";

const useUserTypeController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);

    /**
     * Functionalities
     */

    const getUserTypes = async (filter = null) => {

        try {
            setLoaded(false);
            const data = await UserTypeService.getUserTypes(filter);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const getUserType = async (id) => {
        if (id == null || id.trim().length === 0) return null;
        const usertype = await UserTypeService.getUserType(id);
        translate(usertype, auth.lang);
        return usertype;
    }    
    
    const deletable = async (usertype) => {
        return usertype.id && await UserTypeService.isDeletable(usertype);
    }

    const create = async () => {
        const userType = await UserTypeService.create();
        translate(userType, auth.lang);
        return userType;
    }

    const save = async (usertype) => {

        try {
            untranslate(usertype);
            await UserTypeService.save(usertype);
        }
        finally {
            translate(usertype, auth.lang);
        }
    }

    const remove = async (usertype) => {
        await UserTypeService.remove(usertype);
    }

    const translate = (userType) => {
        LangUtil.translate(userType, 'name', auth.lang);
        LangUtil.translate(userType, 'description', auth.lang);
    }

    const untranslate = (userType) => {
        LangUtil.untranslate(userType, 'name');
        LangUtil.untranslate(userType, 'description');
    }

    const retranslate = (userTypes) => {

        userTypes.forEach(userType => {
            untranslate(userType);
            translate(userType);
        })
    }

    return {
        loaded,
        getUserType,
        getUserTypes,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useUserTypeController;