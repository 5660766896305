
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/motions`;

const MotionService = () => {

    const getMotion = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getMotions = async (company, filter) => {

        const sort = filter?.sort && filter.sort.map(item => item.name + ':' + item.sort).join(',');

        const query = (company || filter ? '?' : '') +
            (company ? `company=${company.id}` : '') +
            (company && filter ? '&' : '') +
            (filter ? `offset=${filter.offset}&limit=${filter.limit}&text=${filter.text ?? ''}&sort=${sort}` : '');

        return (await axios.get(`${SERVER_URL}${query}`)).data.payload;
    }

    const getMatchingsForMotion = async (motionId, wishes, limit, text) => {
        const query = `?motion=${motionId}${limit ? `&limit=${limit}` : ''}${text ? `&text=${text}`: ''}`
        return (await axios.put(`${SERVER_URL}/matchings${query}`, { wishes })).data.payload;
    }

    const getMatchingsForUser = async (userId, wishes, limit, text) => {
        const query = `?user=${userId}${limit ? `&limit=${limit}` : ''}${text ? `&text=${text}`: ''}`
        return (await axios.put(`${SERVER_URL}/matchings${query}`, { wishes })).data.payload;
    }

    const create = async (company, owner, lang) => {
        return (await axios.get(`${SERVER_URL}/create?company=${company.id}&owner=${owner.id}&lang=${lang}`)).data.payload;
    }

    const isDeletable = async (motion) => {
        return (await axios.get(`${SERVER_URL}/${motion.id}/deletable`)).data.payload;
    }

    const save = async (motion) => {

        return motion.id
            ? (await axios.put(`${SERVER_URL}/${motion.id}`, motion)).data.payload
            : (await axios.post(`${SERVER_URL}`, motion)).data.payload;
    }

    const clone = async (motion) => {
        return (await axios.post(`${SERVER_URL}/${motion.id}/clone`)).data.payload;
    }

    const remove = async (motion) => {
        await axios.delete(`${SERVER_URL}/${motion.id}`);
    }

    return {
        getMotions,
        getMotion,
        getMatchingsForMotion,
        getMatchingsForUser,
        create,
        isDeletable,
        save,
        clone,
        remove
    }
}

export default MotionService();