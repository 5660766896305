
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DateUtil from '../../../utils/DateUtil.js';

import useModalEffect from '../../../hooks/useModalEffect.js';
import useExperienceController from '../../../controllers/useExperienceController.js';
import useSectorController from '../../../controllers/useSectorController.js';

const ExperienceDetail = ({ id, experience: theExperience, onSave }) => {

    /**
     * Page state
     */

    const modalRef = useRef();
    const { t } = useTranslation();
    const [experience, setExperience] = useState({});
    const [sectors, setSectors] = useState([]);
    const [deletable, setDeletable] = useState(false);
    
    const experienceController = useExperienceController();
    const sectorController = useSectorController();

    /**
     * Page lifecycle
     */

    useModalEffect(modalRef, async () => {

        theExperience.start = DateUtil.toIsoString(theExperience.start);
        theExperience.end = DateUtil.toIsoString(theExperience.end);
        setExperience(theExperience);
        
        const sectors = await sectorController.getSectors();
        setSectors(sectors.items);
        
        const deletable = await experienceController.deletable(theExperience);
        setDeletable(deletable);
    })

    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setExperience({ ...experience, ...attributes });
    }

    const handleSectorChange = (e) => {
        const sector = sectors.find(s => s.id === e.target.value);
        setExperience({ ...experience, sector });
    }

    return (
        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.experience')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>

                        <div className='row pt-2'>

                            <div className='col form-group'>
                                <label htmlFor='start'>{t('experiences.start')}</label>
                                <input id='start' type='date' className='form-control' value={experience.start ?? ''} placeholder={t('experiences.start-placeholder')} onChange={(e) => handleChange({ start: e.target.value })} />
                            </div>

                            <div className='col form-group'>
                                <label htmlFor='end'>{t('experiences.end')}</label>
                                <input id='end' type='date' className='form-control' value={experience.end ?? ''} placeholder={t('experiences.end-placeholder')} onChange={(e) => handleChange({ end: e.target.value })} />
                            </div>

                        </div>

                        <div className='row pt-2'>
                            <div className='col form-group'>
                                <label htmlFor='position'>{t('experiences.position')}</label>
                                <input id='position' type='text' className='form-control' value={experience.positionTitle ?? ''} placeholder={t('experiences.position-placeholder')} onChange={(e) => handleChange({ positionTitle: e.target.value })} />
                            </div>
                        </div>

                        <div className='row pt-2'>
                            <div className='col form-group'>
                                <label htmlFor='company'>{t('experiences.company')}</label>
                                <input id='company' type='text' className='form-control' value={experience.company ?? ''} placeholder={t('experiences.company-placeholder')} onChange={(e) => handleChange({ company: e.target.value })} />
                            </div>
                        </div>

                        <div className='row pt-2'>
                            <div className='col form-group'>
                                <label htmlFor='sector'>{t('glossary.sector')}</label>
                                <select id='sector' className='form-select' value={experience.sector?.id ?? "1"} onChange={handleSectorChange}>
                                    <option value={null}>{t('glossary.none')}</option>
                                    {sectors?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>
                        </div>

                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>

                            <div>
                                <button type='button' className='btn btn-light' data-bs-dismiss='modal' data-bs-toggle='modal' data-bs-target='#delete' disabled={!deletable}>{t('button.delete')}</button>
                            </div>

                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => onSave(experience)}>{t('button.save')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ExperienceDetail;
