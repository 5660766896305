
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/companies`;

const CompanyService = () => {

    const getCompanies = async (filter) => {

        let query = filter ? '?' : '';

        if (filter) {
            const sort = filter.sort.map(item => item.name + ':' + item.sort).join(',');
            query += `offset=${filter.offset ?? ''}&limit=${filter.limit ?? ''}&text=${filter.text ?? ''}&sort=${sort}`;
        }

        return (await axios.get(`${SERVER_URL}${query}`)).data.payload;
    }

    const getCompany = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getPicture = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}/picture`)).data.payload;
    }

    const create = async () => {
        return (await axios.get(`${SERVER_URL}/create`)).data.payload;
    }

    const isDeletable = async (company) => {
        return (await axios.get(`${SERVER_URL}/${company.id}/deletable`)).data.payload;
    }

    const save = async (company) => {

        return company.id
            ? (await axios.put(`${SERVER_URL}/${company.id}`, company)).data.payload
            : (await axios.post(`${SERVER_URL}`, company)).data.payload;
    }

    const remove = async (company) => {
        await axios.delete(`${SERVER_URL}/${company.id}`);
    }

    return {
        getCompanies,
        getCompany,
        getPicture,
        create,
        isDeletable,
        save,
        remove
    }
}

export default CompanyService();