
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useUserTypeController from '../../controllers/useUserTypeController.js';
import useWorkplaceController from '../../controllers/useWorkplaceController.js';
import usePositionTypeController from '../../controllers/usePositionTypeController.js';

import './UserBox.css';

const UserBox = ({ user }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const [userType, setUserType] = useState();
    const [workplace, setWorkplace] = useState();
    const [positionType, setPositionType] = useState();
    
    const userTypeController = useUserTypeController();
    const workplaceController = useWorkplaceController();
    const positionTypeController = usePositionTypeController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            const userTypes = await userTypeController.getUserTypes();
            const workplaces = await workplaceController.getWorkplaces();
            const positionTypes = await positionTypeController.getPositionTypes();
    
            const userType = user && userTypes.items.find(t => t.id === user.userType);
            const workplace = user && workplaces.items.find(w => w.id === user.workplace);
            const positionType = user && positionTypes.items.find(p => p.id === user.positionType);
    
            setUserType(userType);
            setWorkplace(workplace);
            setPositionType(positionType);
    
        })()

        // eslint-disable-next-line
    }, [user])

    return (

        <div className='user-card border rounded p-4'>

            <div className='title text-center'>
                {
                    !user &&
                    <span>{t('charts.nobody')}</span>
                }
                {
                    user && user.firstname && user.lastname &&
                    <span>{user.firstname} {user.lastname}</span>
                }
            </div>

            <table className='user-data table table-sm'>
                <tbody>
                    <tr><td>{t('charts.age')}</td><td>{user?.age ?? '-'}</td></tr>
                    <tr><td>{t('users.gender')}</td><td>{user?.gender ?? '-'}</td></tr>
                    <tr><td>{t('glossary.workplace')}</td><td>{workplace?.name ?? '-'}</td></tr>
                    <tr><td>{t('users.team')}</td><td>{user?.team ?? '-'}</td></tr>
                    <tr><td>{t('glossary.usertype')}</td><td>{userType?.name ?? '-'}</td></tr>
                    <tr><td>{t('glossary.postype')}</td><td>{positionType?.name ?? '-'}</td></tr>
                    <tr><td>{t('charts.score')}</td><td>{user && user.matchingScore ? user.matchingScore.toFixed() : '-'}</td></tr> 
                    <tr><td>{t('surveys.mindset')}</td><td>{user && user.mindsetScore ? user.mindsetScore.toFixed() : '-'}</td></tr> 
                </tbody>
            </table>

        </div>
    )
}

export default UserBox;