
import React from 'react';
// import bootstrap from 'bootstrap/dist/js/bootstrap.js';
// import Modal from 'bootstrap/dist/js/bootstrap.js';

import { Modal } from 'bootstrap';


const ReactUtil = () => {
    
    const show = (modalId) => {
        Modal.getOrCreateInstance(`#${modalId}`).show();
    }

    const hide = (modalId) => {
        Modal.getOrCreateInstance(`#${modalId}`).show();
    }

    /**
     * Return a reference to a bootstrap Modal from its id
     * @Deprecated
     */
    const getModalById = (id) => {
        // return new Modal(document.getElementById(id));
    }    

    /**
     * Convert react children (wether single or multiple children into an arry
     */
    const toArray = (children) => {
        return React.Children.toArray(children)
    }    

    /**
     * React deep clone
     * @Deprecated
     */
    const clone = (element, props) => {

        let newElement = null;
        const newProps = { ...element.props, ...props };
    
        if (newProps.children) {
            newProps.children = React.Children.map(element.props.children, (child, i) => clone(child, { ...child.props, key: i }));
        }
    
        if (React.isValidElement(element)) {
            newElement = React.cloneElement(element, newProps);
        }
        else {
            newElement = <span>{element}</span>;
        }
    
        return newElement;
    }

    return {
        show,
        hide,
        clone,
        toArray,
        getModalById,
    }
}

export default ReactUtil();