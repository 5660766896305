
import { useTranslation } from 'react-i18next';
import unsort_img from '../../assets/images/sort-none.svg'; 
import sort_ascending_img from '../../assets/images/sort-down.svg'; 
import sort_descending_img from '../../assets/images/sort-up.svg'; 

import './TableHeader.css';

const TableHeader = ({ descriptor, filter, onChange, ...props }) => {
    
    const { t } = useTranslation();
    const { property, display, sortable = true } = descriptor;
    const filterColumn = filter.sort.find(column => column.name === property) ?? { name: property, sort: 0 };

    const handleClick = (e) => {

        if (filterColumn.sort === 0) {
            filterColumn.sort = 1; // ascending
        }
        else if (filterColumn.sort === 1) {
            filterColumn.sort = -1; // descending
        }
        else {
            filterColumn.sort = 0; // unsorted
        }

        // Update the filter column withihn the filter
        filter.sort = filter.sort.filter(item => item.name !== property);
        if (filterColumn.sort !== 0) filter.sort.push(filterColumn);

        onChange && onChange({ ...filter });
    }

    return (
        <th>
            <div {...props} className={`table-header-new ${sortable ? 'sortable' : ''} ${props.className}`} onClick={handleClick}>

                { t(display) }
                
                {
                    sortable &&
                    <div className='ps-1' onClick={handleClick}>
                        { filterColumn.sort ===  0 && <img src={unsort_img } alt='unsorted' /> }
                        { filterColumn.sort ===  1 && <img src={sort_ascending_img } alt='ascending' /> }
                        { filterColumn.sort === -1 && <img src={sort_descending_img } alt='descending' /> }
                    </div>
                }

            </div>
        </th>
    )
}

export default TableHeader;