
import config from '../../package.json';

const AppLogic = () => {

    // const REACT_APP_A XIOS_REQUEST = process.env.REACT_APP_AXIOS_REQUEST ?? false;

    const version = config.version;
    const serverUrl = config.serverUrl ?? '"http://localhost:7000';
    const pageLimit = config.pageLimit ?? 50;
    const traceHttpRequests = config.traceHttpRequests ?? false;

    return {
        version,
        serverUrl,
        pageLimit,
        traceHttpRequests
    }
}

export default AppLogic();

