
import { useTranslation } from 'react-i18next';

const Paginator = ({ filter = {}, onChange, ...props }) => {

    /**
     * Component state
     */

    const { t } = useTranslation();
    const { offset, limit, count } = filter;
    const pageCount = count > 0 && limit > 0 ? Math.ceil(count / limit) : 0;
    const currentPage = offset > 0 && limit > 0 ? offset / limit : 0;

    /**
     * Event handler
     */

    const handleClick = (page) => {
        onChange({ ...filter, offset: page * limit });
    }

    if (count > limit)
        return (
            <nav>
                <ul {...props} className={'mt-3 pagination flex-sm-wrap justify-content-center' + (props.className ? ` ${props.className}` : '')}>

                    <li className={'page-item' + (currentPage === 0 ? ' disabled' : '')}>
                        <button className='page-link' onClick={() => handleClick(currentPage - 1)}>{t('button.previous')}</button>
                    </li>

                    {
                        [...Array(pageCount).keys()].map((page) => {
                            return (
                                <li key={page} className={'page-item' + (page === currentPage ? ' active' : '')}>
                                    <button className='page-link' onClick={() => handleClick(page)}>{page + 1}</button>
                                </li>)
                        })
                    }

                    <li className={'page-item' + (currentPage > pageCount - 2 ? ' disabled' : '')}>
                        <button className='page-link' onClick={() => handleClick(currentPage + 1)}>{t('button.next')}</button>
                    </li>

                </ul>
            </nav>)
}

export default Paginator;