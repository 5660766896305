
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useInvitationController from '../../../controllers/useInvitationController.js';

import AppLogic from '../../../logic/AppLogic.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import Paginator from '../../../components/filter/Paginator.js';
import DateUtil from '../../../utils/DateUtil.js';
import SurveyLogic from '../../../logic/SurveyLogic.js';

import edit_img from '../../../assets/images/pen.svg';

const Invitations = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const navigate = useNavigate();
    const [filter, setFilter] = useState({ offset: 0, limit: AppLogic.pageLimit, sort: [] });
    const [invitations, setInvitations] = useState([]);

    const invitationController = useInvitationController(filter);

    const columns = {
        date: { property: 'creation', display: 'surveys.date' },
        due: { property: 'dueDate', display: 'surveys.duedate' },
        name: { property: 'name', display: 'surveys.name' },
        owner: { property: 'ownerLastname', display: 'surveys.owner' },
        company: { property: 'company', display: 'glossary.company' },
        status: { property: 'status', display: 'surveys.status' }
    }

    /**
     * Page lifecycle
     */

    const reload = async (filter) => {
        const data = await invitationController.getInvitations(filter);
        setInvitations(data.items);
        setFilter(data.filter);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [])

    // eslint-disable-next-line 
    useEffect(() => { invitationController.retranslate(invitations); setInvitations([...invitations]); }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleDetail = (id) => {
        navigate('/user/invitations/' + id);
    }

    const handleFilter = (filter) => {
        reload(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!invitationController.loaded} />

            <div className='row p-4'>


                <TitleBar title={t('glossary.surveys')} filter={filter} onChange={handleFilter} />

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>

                            <TableHeader descriptor={columns.date} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.due} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.name} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.owner} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.company} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.status} filter={filter} onChange={handleFilter} />

                            <th className='text-center'>
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {
                            invitations.map((invitation) => (
                                <tr key={invitation.id} className='align-middle'>

                                    <td>{DateUtil.toLocaleString(invitation.creation)}</td>
                                    <td>{DateUtil.toLocaleString(invitation.survey?.dueDate)}</td>
                                    <td>{invitation.survey.name}</td>
                                    <td>{invitation.survey.owner.email}</td>
                                    <td>{invitation.survey.company.name}</td>
                                    <td>{SurveyLogic.toStringStatus(invitation.status)}</td>

                                    <td className='text-center'>
                                        <button className='btn btn-light' onClick={() => handleDetail(invitation.id)}>
                                            <img src={edit_img} alt='edit' />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default Invitations
