
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/users`;

const UserService = () => {

    const getUsers = async (company, filter) => {

        let query = company || filter ? '?' : '';
        if (company) query += 'company=' + company.id + (filter ? '&' : '');

        if (filter) {
            const sort = filter.sort.map(item => item.name + ':' + item.sort).join(',');
            query += `offset=${filter.offset ?? ''}&limit=${filter.limit ?? ''}&text=${filter.text ?? ''}&sort=${sort}`;
        }

        return (await axios.get(`${SERVER_URL}${query}`)).data.payload;
    }

    const getUser = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const getPicture = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}/picture`)).data.payload;
    }

    const create = async (company) => {
        return (await axios.get(`${SERVER_URL}/create?company=${company.id}`)).data.payload;
    }

    const isDeletable = async (user) => {
        return (await axios.get(`${SERVER_URL}/${user.id}/deletable`)).data.payload;
    }

    const save = async (user) => {

        return user.id
            ? (await axios.put(`${SERVER_URL}/${user.id}`, user)).data.payload
            : (await axios.post(`${SERVER_URL}`, user)).data.payload;
    }

    const remove = async (user) => {
        await axios.delete(`${SERVER_URL}/${user.id}`);
    }

    return {
        getUsers,
        getUser,
        getPicture,
        create,
        isDeletable,
        save,
        remove
    }
}

export default UserService();