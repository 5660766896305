
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useModalEffect from '../../../hooks/useModalEffect.js';
import useSectorController from '../../../controllers/useSectorController.js';

const SectorDetail = ({ id, sector: theSector, onSave }) => {

    /**
     * Page state
     */

    const modalRef = useRef();
    const { t } = useTranslation();
    const [sector, setSector] = useState({});
    const [deletable, setDeletable] = useState(false);
    
    const sectorController = useSectorController();

    /**
     * Page lifecycle
    */

    useModalEffect(modalRef, async () => {

        setSector(theSector);
        
        const deletable = await sectorController.deletable(theSector);
        setDeletable(deletable);
    })

    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setSector({ ...sector, ...attributes });
    }

    return (
        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.sector')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>

                        <div className='col-6 form-group'>
                            <label htmlFor='name'>{t('sectors.code')}</label>
                            <input id='name' type='text' className='form-control' value={sector.code ?? ''} placeholder={t('sectors.code-placeholder')} onChange={(e) => handleChange({ code: e.target.value })} />
                        </div>

                        <div className='col form-group'>
                            <label htmlFor='description'>{t('sectors.name')}</label>
                            <input id='description' type='text' className='form-control' value={sector.name ?? ''} placeholder={t('sectors.name-placeholder')} onChange={(e) => handleChange({ name: e.target.value })} />
                        </div>

                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>

                            <div>
                                <button type='button' className='btn btn-light' data-bs-dismiss='modal' data-bs-toggle='modal' data-bs-target='#delete' disabled={!deletable}>{t('button.delete')}</button>
                            </div>

                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => onSave(sector)}>{t('button.save')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SectorDetail;
