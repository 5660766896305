
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import { useNavigate } from 'react-router-dom';
import useCatalogController from '../../../controllers/useCatalogController.js';
import useTooltips from '../../../hooks/useTooltips.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import CatalogLogic from '../../../logic/CatalogLogic.js';

import DeclarativeView from '../../../components/catalog/DeclarativeViewEditable.js';
import NormalizedView from '../../../components/catalog/NormalizedViewEditable.js';
import LanguageView from '../../../components/catalog/LanguageViewEditable.js';

const SkillCatalog = () => {


    /**
     * Page state
     */

    const navigate = useNavigate();
    const { auth } = useAuthContext();
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [catalog, setCatalog] = useState({});
    const [categories, setCategories] = useState([]);

    useTooltips(); // bootstrap init
    const catalogController = useCatalogController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            try {
                setLoaded(false);

                // Get user's current skills
                const catalog = await catalogController.getUserCatalog(auth.user, auth.lang);

                // Get the categories from the catalog
                const categories = catalog?.children ?? [];

                setCatalog(catalog);
                setCategories(categories);
            }
            finally {
                setLoaded(true);
            }

        })()

        // eslint-disable-next-line 
    }, [auth.user]);

    useEffect(() => {

        catalogController.retranslate(catalog);
        setCatalog({ ...catalog });

        // eslint-disable-next-line 
    }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleChange = (category) => {
        CatalogLogic.mergeCategory(catalog, category);
        setCatalog({ ...catalog });
    }

    const handleSave = async () => {
        await catalogController.saveUserCatalog(auth.user, catalog);
        navigate('/home');
    }

    const handleCancel = () => {
        navigate('/home');
    }

    return (

        <div className='container'>
            <div className='row p-4'>

                <LoadingIcon loading={!loaded} />

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('profile.skills')}</h5>
                </div>

                <hr />

                <ul className='nav nav-tabs pt-3'>
                    {
                        categories.map((category, i) => (
                            <li key={i}>
                                <button className={'nav-link' + (i === 0 ? ' active' : '')} data-bs-toggle='tab' data-bs-target={'#tab' + i}>
                                    {category.label}
                                </button>
                            </li>
                        ))
                    }
                </ul>

                <div className='tab-content py-4'>
                    {
                        categories.map((category, i) => {

                            const isDeclarative = CatalogLogic.isDeclarative(category);
                            const isNormalized = CatalogLogic.isNormalized(category);
                            const isLanguage = CatalogLogic.isLanguage(category);

                            return (
                                <div id={'tab' + i} key={i} className={'tab-pane fade' + (i === 0 ? ' show active' : '')}>
                                    {isDeclarative && <DeclarativeView category={category} onChange={handleChange} />}
                                    {isNormalized && <NormalizedView category={category} onChange={handleChange} />}
                                    {isLanguage && <LanguageView category={category} onChange={handleChange} />}
                                </div>
                            )
                        })
                    }
                </div>

            </div>

            <div className='row pt-3'>

                <hr />

                <div className='d-flex justify-content-end pb-4'>

                    <div>

                    </div>

                    <div className='btn-group'>
                        <button className='btn btn-light' onClick={handleSave}>{t('button.save')}</button>
                        <button className='btn btn-light' onClick={handleCancel}>{t('button.cancel')}</button>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default SkillCatalog;
