
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../controllers/useAuthContext.js';
import useWishController from '../../controllers/useWishController.js';
import TreeUtil from '../../utils/TreeUtil.js';

const WishesView = ({ wishes, onChange }) => {

    /**
     * Page state 
     */

    const { auth } = useAuthContext();
    const [wishTree, setWishTree] = useState({});

    const wishController = useWishController();

    /**
     * Page lifecycle
     */

    const reload = async () => {

        // Build a single tree of wishes with category/wishes/subtypes
        const allwishes = await wishController.getWishes();
        const categories = allwishes.items.filter(w => w.parent == null);
        const wishTree = { id: 'root', children: categories };

        // Select all the wishes in the tree, defined in the user
        const wishmap = new Map(wishes ? wishes.map(wishId => { return [wishId, wishId] }) : null);
        TreeUtil.toList(wishTree).forEach(wish => wish.checked = wishmap.has(wish.id));

        setWishTree({ ...wishTree });
    }

    // eslint-disable-next-line 
    useEffect(() => { reload() }, [wishes, auth.lang])

    /**
     * Event handlers
     */

    const handleToogle = (wish) => {

        const wishlist = TreeUtil.toList(wishTree);

        // Toggle the wish
        wish.checked = !wish.checked;
        wish.children.forEach(w => w.checked = wish.checked);

        // Wish without a type are subtype, so also update its parent
        if (wish.parent && !wish.type) {
            const parent = wishlist.find(w => w.id === wish.parent);
            parent.checked = parent.children.reduce((checked, item) => checked || item.checked, false);
        }

        // Rebuild a list of all wishes selected by the user
        const wishes = wishlist.filter(wish => wish.checked).map(wish => wish.id);
        onChange({ wishes });
    }

    return (

        <div>

            {
                wishTree.children?.map((wish, i) => {

                    return (
                        <fieldset key={wish.id} className='form-group border rounded mx-3 px-3 pb-3 vh-50 mt-2'>

                            <legend className={'float-none w-auto px-2 fs-6'}>{wish.name}</legend>

                            {
                                wish.children.map((subwish) => {

                                    return (
                                        <div key={subwish.id} className='m-1'>

                                            <div>
                                                <input id={subwish.id} className='form-check-input' type='checkbox' checked={subwish.checked ?? false} onChange={() => handleToogle(subwish)} />
                                                <label htmlFor={subwish.id} className='form-check-label ps-2'>{subwish.name}</label>
                                            </div>

                                            {
                                                subwish.children.length > 1 &&
                                                <div className='btn-group ps-4 pb-2'>
                                                    {
                                                        subwish.children.map((subsubwish) => {

                                                            return (
                                                                <div key={subsubwish.id} className='btn-group'>
                                                                    <input id={subsubwish.id} className='btn-check' type='checkbox' checked={subsubwish.checked ?? false} onChange={() => handleToogle(subsubwish)} />
                                                                    <label htmlFor={subsubwish.id} className='btn btn-sm btn-outline-primary'>{subsubwish.name}</label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }

                                        </div >
                                    )
                                })
                            }

                        </fieldset>
                    )
                })
            }
        </div>
    )
}

export default WishesView;
