
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import SectorService from '../services/SectorService.js';
import LangUtil from "../utils/LangUtil.js";

const useSectorController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);

    /**
     * Functionalities
     */

    const getSectors = async (filter = null) => {

        try {
            setLoaded(false);
            const data = await SectorService.getSectors(filter);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const getSector = async (id) => {
        if (id == null || id.trim().length === 0) return null;
        const sector = await SectorService.getSector(id);
        translate(sector, auth.lang);
        return sector;
    }    

    const deletable = async (sector) => {
        return sector.id != null && await SectorService.isDeletable(sector);
    }

    const create = async () => {
        const sector = await SectorService.create();
        translate(sector);
        return sector;
    }

    const save = async (sector) => {

        try {
            untranslate(sector);
            await SectorService.save(sector);
        }
        finally {
            translate(sector);
        }
    }

    const remove = async (sector) => {
        await SectorService.remove(sector);
    }

    const translate = (sector) => {
        LangUtil.translate(sector, 'name', auth.lang);
    }

    const untranslate = (sector) => {
        LangUtil.untranslate(sector, 'name');
    }

    const retranslate = (sectors) => {

        sectors.forEach(sector => {
            untranslate(sector);
            translate(sector);
        })
    }

    return {
        loaded,
        getSector,
        getSectors,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useSectorController;