
import star_img from '../../assets/images/star.svg';

const RadarPicture = ({ entity, position, metrics, onClick }) => {

        // Calculate third dimension distance (gauge)
        const circum = Math.PI * 2 * metrics.iconSize
        const length = circum * position.z;
        const space  = circum - length;

        return (
            <g key={entity.id} className='profile' transform={`translate(${position.x}, ${position.y})`} fontSize={metrics.fontSize} onClick={(e) => onClick(e, entity)}>

                {
                    !entity.picture && 
                    <g>
                        <circle cx='0' cy='0' r={metrics.iconSize} fill='white' stroke='white' />
                        <text x='0' y='0' textAnchor='middle' dominantBaseline='middle'>{entity.label ?? ''}</text>
                        <circle cx='0' cy='0' r={metrics.iconSize} fill='none' stroke='#dfdfdf' strokeWidth={metrics.circleThickness} />
                        <circle cx='0' cy='0' r={metrics.iconSize} fill='none' stroke='#f8932e' strokeWidth={metrics.circleThickness} strokeDasharray={length + ' ' + space} /> 
                    </g>
                }

                {
                    entity.picture && 
                    <g>
                        <circle cx='0' cy='0' r={metrics.iconSize} fill='white' stroke='white' />
                        <clipPath id={entity.id}><circle cx='0' cy='0' r={metrics.iconSize} /></clipPath>
                        <image x={-metrics.iconSize} y={-metrics.iconSize} width={metrics.iconSize*2} height={metrics.iconSize*2} href={entity.picture} clipPath={`url(#${entity.id})`} /> 
                        <text x='0' y={metrics.iconSize * 1.3} textAnchor='middle' dominantBaseline='hanging'>{entity.label ?? ''}</text>
                        <circle cx='0' cy='0' r={metrics.iconSize} fill='none' stroke='#dfdfdf' strokeWidth={metrics.circleThickness} />
                        <circle cx='0' cy='0' r={metrics.iconSize} fill='none' stroke='#f8932e' strokeWidth={metrics.circleThickness} strokeDasharray={length + ' ' + space} />
                    </g>
                }

                {
                    entity.like &&
                    <image x={0} y={0} width={metrics.iconSize/1.6} height={metrics.iconSize/1.6} href={star_img} />
                } 

            </g>
        )  
}

export default RadarPicture;
