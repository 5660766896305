
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';

import useSurveyController from '../../../controllers/useSurveyController.js';
import useCatalogController from '../../../controllers/useCatalogController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import CatalogLogic from '../../../logic/CatalogLogic.js';
import InvitationLogic from '../../../logic/InvitationLogic.js';
import TreeUtil from '../../../utils/TreeUtil.js';

import TabOptions from './TabOptions.js';
import TabInvitations from './TabInvitations.js';
import DeclarativeView from '../../../components/catalog/DeclarativeViewSelectable.js';
import NormalizedView from '../../../components/catalog/NormalizedViewSelectable.js';
import LanguageView from '../../../components/catalog/LanguageViewSelectable.js';
import SurveyDelete from './SurveyDelete.js';

import chart_img from '../../../assets/images/chart.svg';

const SurveyDetail = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const { id } = useParams();
    const navigate = useNavigate();

    const [survey, setSurvey] = useState({});
    const [surveyCatalog, setSurveyCatalog] = useState({});
    const [surveyCategories, setSurveyCategories] = useState([]);
    const [companyCatalog, setCompanyCatalog] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [deletable, setDeletable] = useState(false);

    const surveyController = useSurveyController();
    const catalogController = useCatalogController();

    /**
     * Page lifecycle
     */

    const reload = async () => {

        try {
            setLoaded(false);

            // Get the survey (or create a new one)
            const survey = await surveyController.getSurvey(id);

            // Check if it is deletable
            const deletable = await surveyController.deletable(survey);
                
            // Get the company catalog
            const companyCatalog = await catalogController.getCompanyCatalog(auth.company);

            // Make all existing invitations visible
            InvitationLogic.markAsVisible(survey.invitations);

            // Update view state
            setSurvey(survey);
            setCompanyCatalog(companyCatalog)
            setSurveyCatalog(survey.catalog);
            setSurveyCategories(survey.categories);
            setDeletable(deletable);
        }
        finally {
            setLoaded(true);
        }
    }

    // eslint-disable-next-line
    useEffect(() => { reload() }, [id, auth.lang]);

    /**
     * Event handlers
     */

    const handleSurveyChange = (attributes) => {
        setSurvey({ ...survey, ...attributes });
    }

    const handleCategoryChange = (category) => {
        CatalogLogic.mergeCategory(surveyCatalog, category);
        setSurveyCatalog({ ...surveyCatalog });
    }

    const handleInvitationsChange = (invitations) => {
        setSurvey({ ...survey, invitations });
    }

    const handleSendInvitations = async (survey, done) => {
        await surveyController.sendInvitations(survey);
        await reload();
        done && done();
    }

    const handleSave = async () => {
        await surveyController.save({ ...survey, catalog: surveyCatalog });
        navigate('/manager/surveys');
    }

    const handleClone = async () => {
        await surveyController.clone(survey);
        navigate('/manager/surveys');
    }

    const handleDelete = async () => {
        await surveyController.remove(survey);
        navigate('/manager/surveys');
    }

    const handleCancel = () => {
        navigate('/manager/surveys');
    }

    const handleChart = (survey) => {
        navigate('/manager/surveys/' + survey.id + '/charts');
    }

    return (

        <div className='container-fluid'>

            <LoadingIcon loading={!loaded} />
            <SurveyDelete id='deleteSurvey' survey={survey} onDelete={handleDelete} />

            <div className='row p-4'>

                <div className='d-flex align-items-center justify-content-between'>
                    <h5 className='text-muted'>{t('glossary.survey')} - {survey.name}</h5>
                    {survey.id && <button className='btn btn-light' onClick={() => handleChart(survey)}><img src={chart_img} alt='chart' /></button>}
                </div>

                <hr />

                <ul className='nav nav-tabs'>

                    <li>
                        <button className='nav-link active' data-bs-toggle='tab' data-bs-target='#tab0' type='button'>
                            {t('surveys.options')}
                        </button>
                    </li>

                    <li>
                        <button className='nav-link' data-bs-toggle='tab' data-bs-target='#tab1' type='button'>
                            {t('surveys.invitations')}
                        </button>
                    </li>

                    {
                        surveyCategories.map((category, i) => (
                            <li key={i}>
                                <button className='nav-link' data-bs-toggle='tab' data-bs-target={'#tab' + (i + 2)} type='button' disabled={!category?.state.selected}>
                                    {category.label}
                                </button>
                            </li>
                        ))
                    }

                </ul>

                <div className='tab-content py-4'>

                    <div id='tab0' className='tab-pane fade show active'>
                        <TabOptions survey={survey} categories={surveyCategories} onChange={handleSurveyChange} />
                    </div>

                    <div id='tab1' className='tab-pane fade'>
                        <TabInvitations survey={survey} onChange={handleInvitationsChange} onSend={handleSendInvitations} />
                    </div>

                    {
                        surveyCategories.map((surveyCategory, i) => {
                            
                            const isDeclarative = CatalogLogic.isDeclarative(surveyCategory);
                            const isNormalized = CatalogLogic.isNormalized(surveyCategory);
                            const isLanguage = CatalogLogic.isLanguage(surveyCategory);

                            const companyCategory = TreeUtil.findById(companyCatalog, surveyCategory.id);

                            return (
                                <div id={'tab' + (i + 2)} key={i} className='tab-pane fade'>
                                    {isDeclarative && <DeclarativeView companyCategory={companyCategory} surveyCategory={surveyCategory} onChange={handleCategoryChange} />}
                                    {isNormalized && <NormalizedView category={surveyCategory} onChange={handleCategoryChange} />}
                                    {isLanguage && <LanguageView category={surveyCategory} onChange={handleCategoryChange} />}
                                </div>
                            )
                        })
                    }

                </div>

            </div>

            <div className='row px-4'>

                <hr />

                <div className='d-flex justify-content-between pb-4'>

                    <div className='btn-group'>
                        <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#deleteSurvey' disabled={!deletable}>{t('button.delete')}</button>
                    </div>

                    <div className='btn-group'>
                        <button className='btn btn-light' onClick={handleSave}>{t('button.save')}</button>
                        <button className='btn btn-light' disabled={!deletable} onClick={handleClone}>{t('button.clone')}</button>
                        <button className='btn btn-light' onClick={handleCancel}>{t('button.cancel')}</button>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default SurveyDetail;
