
import axios from "axios";
import { useAuthContext } from './useAuthContext.js';
import { useTranslation } from 'react-i18next';
import AuthenticationService from '../services/AuthenticationService.js';

const useAuthenticationController = () => {

    const { i18n } = useTranslation();
    const { auth, setAuth } = useAuthContext();

    const createContext = (tokenAndUser) => {

        const token   = tokenAndUser?.token ?? null;
        const user    = tokenAndUser?.user ?? null;
        const lang    = user?.lang ?? 'en';
        const company = user?.company ?? null;

        if (user == null || token == null) {
            return clearContext();
        }

        // Add the authentication token to all requests
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };

        // Put the token into the session
        sessionStorage.setItem('token', token);

        // Return a filled context
        return { authenticated: true, token, user, lang, company };
    }

    /**
     * Create a default unauthenticated context
     */
    const clearContext = () => {
        
        axios.defaults.headers.common = {};
        sessionStorage.removeItem('token');

        // Return an empty context
        return { authenticated: false, token: null, user: null, lang: null, company: null };
    }

    /**
     * Check if current user has one of the allowed roles
     */
    const hasRole = (roles) => {

        if (auth?.user?.roles?.length > 0) {

            const userRoles = auth.user.roles;
            const expectedRoles = typeof roles === 'string' ? [roles] : roles;
            const intersect = userRoles.filter(role => expectedRoles.includes(role));

            return intersect?.length > 0;
        }

        return false;
    }

    const resetPassword = async (email) => {
        return await AuthenticationService.reset(email);
    }

    const registerUser = async (user) => {
        return await AuthenticationService.register(user);
    }

    const changePassword = async (resetCode, newPassword) => {
        return await AuthenticationService.password(resetCode, newPassword);
    }

    const isResetCodeValid = async (code) => {
        return await AuthenticationService.check(code);
    }

    /**
     * Authenticate a user from its credentials (email + password)
     * and return an authentication object (auth).
     */
    const login = async (credentials) => {

        const tokenAndUser = await AuthenticationService.login(credentials);
        const ctx = createContext(tokenAndUser);
        setAuth(ctx);

        return ctx;
    }

    /**
     * Authenticate a user from its token
     * and return an authentication object (auth).
     * 
     * Note: using the token, the user doesn't need to login again (autologin feature)
     */
    const autologin = async (token) => {

        const tokenAndUser = await AuthenticationService.autologin(token);
        const ctx = createContext(tokenAndUser);
        setAuth(ctx);

        if (ctx?.authenticated) {

            // Change the current language
            i18n.changeLanguage(ctx.user.lang);
        }
        else {
            throw new Error();
        }
    }

    /**
     * Reset current authentication.
     */
    const logout = () => {
        const ctx = clearContext();
        setAuth(ctx);
        return ctx;
    }

    /**
     * Change the current company
     */
    const changeCompany = async (company) => {
        setAuth({ ...auth, company });
    }

    /**
     * Change the current displayed language
     */
    const changeLanguage = async (lang) => {
        setAuth({ ...auth, lang: lang });
        i18n.changeLanguage(lang);
    }    

    return {
        hasRole,
        createContext,
        resetPassword,
        registerUser,
        changePassword,
        isResetCodeValid,
        login,
        autologin,
        logout,
        changeCompany,
        changeLanguage
    }
}

export default useAuthenticationController;

