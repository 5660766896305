
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCountryController from '../../../controllers/useCountryController.js';

const TabAddress = ({ user, onChange }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const [countries, setCountries] = useState([]);
    const countryController = useCountryController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            const countries = await countryController.getCountries();
            setCountries(countries.items);

        })()

    // eslint-disable-next-line 
    }, [])    

    /**
     * Event handlers
     */

    const handleCountryChange = (e) => {
        const country = countries.find(c => c.id === e.target.value);
        onChange({ ...user, country });
    }

    return (

        <div className='container'>

            <div className='row'>

                <div className='col-9 form-group'>
                    <label htmlFor='street'>{t('users.street')}</label>
                    <input type='text' id='street' className='form-control' value={user?.street ?? ''} placeholder={t('users.street-placeholder')} onChange={(e) => onChange({ street: e.target.value })} />
                </div>

                <div className='col form-group'>
                    <label htmlFor='number'>{t('users.number')}</label>
                    <input type='string' id='number' className='form-control' value={user?.streetNumber ?? ''} placeholder={t('users.number-placeholder')} onChange={(e) => onChange({ streetNumber: e.target.value })} />
                </div>

            </div>

            <div className='row pt-2'>

                <div className='col form-group'>
                    <label htmlFor='streetComplement'>{t('users.complement')}</label>
                    <input type='text' id='streetComplement' className='form-control' value={user?.streetComplement ?? ''} placeholder={t('users.complement-placeholder')} onChange={(e) => onChange({ streetComplement: e.target.value })} />
                </div>

            </div>

            <div className='row pt-2'>

                <div className='col-9 form-group'>
                    <label htmlFor='city'>{t('users.city')}</label>
                    <input type='text' id='city' className='form-control' value={user?.city ?? ''} placeholder={t('users.city-placeholder')} onChange={(e) => onChange({ city: e.target.value })} />
                </div>

                <div className='col form-group'>
                    <label htmlFor='cityCode'>{t('users.citycode')}</label>
                    <input type='string' id='cityCode' className='form-control' value={user?.cityCode ?? ''} placeholder={t('users.citycode-placeholder')} onChange={(e) => onChange({ cityCode: e.target.value })} />
                </div>

            </div>

            <div className='row pt-2'>

                <div className='col form-group'>
                    <label htmlFor='gender'>{t('glossary.country')}</label>
                    <select name='country' className='form-select' value={user.country?.id ?? ''} onChange={handleCountryChange}>
                        <option value={null}>{t('glossary.none')}</option>
                        {countries?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                    </select>
                </div>

            </div>

        </div>
    )
}

export default TabAddress;
