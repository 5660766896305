
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useModalEffect from '../../../hooks/useModalEffect.js';
import useAssessmentController, { AssessmentStatus } from '../../../controllers/useAssessmentController.js';
import DateUtil from '../../../utils/DateUtil.js';

const AssessmentDetail = ({ id, assessment: theAssessment, onStart, onResume, onReport }) => {

    /**
     * Page state
     */

    const modalRef = useRef();
    const { t } = useTranslation();
    const [assessment, setAssessment] = useState({});
    const assessmentController = useAssessmentController();
    const [deletable, setDeletable] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const maritalStatuses = t('assessments.profilscan.marital-statuses');
    const activitySectors = t('assessments.profilscan.activity-sectors');
    const positionTypes = t('assessments.profilscan.position-types');
    const educationLevels = t('assessments.profilscan.eduction-levels');

    /**
     * Page lifecycle
     */

    useModalEffect(modalRef, async () => {

        const deletable = await assessmentController.deletable(theAssessment);

        setAssessment(theAssessment);
        setDeletable(deletable);

        setStartDate(DateUtil.toIsoString(theAssessment.start));
        setEndDate(DateUtil.toIsoString(theAssessment.end));
    })

    /**
     * Event handlers
     */

    const handleChange = (attribute) => {
        setAssessment({ ...assessment, ...attribute });
    }

    return (
        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{assessment.id ? t('assessments.detail') : t('assessments.new')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>

                        <div className='row pt-2'>

                            <div className='col-6 form-group'>
                                <label htmlFor='code'>{t('assessments.code')}</label>
                                <input id='start' type='text' className='form-control' value={assessment.externalId ?? ''} readOnly />
                            </div>

                        </div> 

                        <div className='row pt-2'>

                            <div className='col form-group'>
                                <label htmlFor='start'>{t('assessments.start')}</label>
                                <input id='start' type='text' className='form-control' value={startDate ?? ''} readOnly />
                            </div>

                            <div className='col form-group'>
                                <label htmlFor='end'>{t('assessments.end')}</label>
                                <input id='end' type='text' className='form-control' value={endDate ?? ''} readOnly />
                            </div>

                        </div>

                        <div className='row pt-2'>

                            <div className='col form-group'>
                                <label htmlFor='mstatus'>{t('assessments.profilscan.marital-status-label')}</label>
                                <select id='mstatus' className='form-select' value={assessment.maritalStatus ?? ''} onChange={(e) => handleChange({ maritalStatus: e.target.value })}>
                                    <option value={null}>{t('glossary.none')}</option>
                                    {maritalStatuses.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>

                            <div className='col form-group'>
                                <label htmlFor='asector'>{t('assessments.profilscan.activity-sector-label')}</label>
                                <select id='mstaasectortus' className='form-select' value={assessment.activitySector ?? ''} onChange={(e) => handleChange({ activitySector: e.target.value })}>
                                    <option value={null}>{t('glossary.none')}</option>
                                    {activitySectors.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>

                        </div>

                        <div className='row py-2'>

                            <div className='col form-group'>
                                <label htmlFor='ptype'>{t('assessments.profilscan.position-type-label')}</label>
                                <select id='ptype' className='form-select' value={assessment.positionType ?? ''} onChange={(e) => handleChange({ positionType: e.target.value })}>
                                    <option value={null}>{t('glossary.none')}</option>
                                    {positionTypes.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>

                            <div className='col form-group'>
                                <label htmlFor='elevel'>{t('assessments.profilscan.education-level-label')}</label>
                                <select id='elevel' className='form-select' value={assessment.educationLevel ?? ''} onChange={(e) => handleChange({ educationLevel: e.target.value })}>
                                    <option value={null}>{t('glossary.none')}</option>
                                    {educationLevels.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>

                        </div>

                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>

                            <div>
                                <button type='button' className='btn btn-light' data-bs-dismiss='modal' data-bs-toggle='modal' data-bs-target='#delete' disabled={!deletable}>{t('button.delete')}</button>
                            </div>

                            <div className='btn-group'>
                                
                                { assessment.status === AssessmentStatus.CREATED && <button className='btn btn-light' data-bs-dismiss='modal' onClick={onStart}>{t('button.start')}</button> }
                                { assessment.status === AssessmentStatus.STARTED && <button className='btn btn-light' data-bs-dismiss='modal' onClick={onResume}>{t('button.resume')}</button> }
                                { assessment.status === AssessmentStatus.COMPLETED && <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => onReport(assessment)}>{t('button.report')}</button> }

                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AssessmentDetail;
