
import TreeUtil from '../utils/TreeUtil.js';

const CatalogLogic = () => {

    /**
     * Return true if the node is a tree root.
     */
    const isRoot = (node) => {
        return node.id === 'root';
    }

    /**
     * Return true if the skill is not a tree root.
     */
    const isNotRoot = (node) => {
        return !isRoot(node);

    }

    /**
     * Return true if the skill is a child of the root node.
     * So if the skill is a category node.
     */
    const isCategory = (skill) => {
        return skill?.type?.length > 0;
    }

    /**
     * Return true if the skill is a parent and has some children.
     */
    const isLeaf = (skill) => {
        return skill && (skill.children == null || skill.children.length === 0);
    }

    /**
     * Return true if the skill is disabled, that is 
     * one of its parent has a level defined.
     */

    const isDisabled = (skill) => {
        return skill.level === -1;
    }

    /**
     * Return true if the skill support having a level
     */

    const hasLevel = (skill) => {
        return !isDisabled(skill) && isNotRoot(skill);
    }

    /**
     * Return true if the skill is a declarative category.
     * See backend for more information.
     */
    const isDeclarative = (skill) => {
        return skill?.type === 'declarative';
    }

    /**
     * Return true if the skill is a normalized category.
     * See backend for more information.
     */
    const isNormalized = (skill) => {
        return skill?.type === 'normalized';
    }

    /**
     * Return true if the skill is a language category.
     * See backend for more information.
     */
    const isLanguage = (skill) => {
        return skill?.type === 'language';
    }    

    /**
     * Return true if the skill is a generalization node
     */
    const isGeneralization = (skill) => {
        return skill.childrenType !== 'partition';
    }

    /**
     * Return true if the skill is a composition node ()
     */

    const isComposition = (skill) => {
        return skill.childrenType === 'partition';
    }

    /**
     * Get the level in a skill.
     * Internally a skill has always a 5 level-range.
     * So this method, return the current skill level, 
     * adjusted to the category range (3 or 5 level-range).
     */
    const getSkillLevel = (category, skill) => {
        return category ? (isDeclarative(category) && category.levels < 5 ? +skill.level - 1 : +skill.level ?? 0) : null;
    }

    const getSkillExpectedLevel = (category, skill) => {
        return category ? (isDeclarative(category) && category.levels < 5 ? +skill.expectedLevel - 1 : +skill.expectedLevel ?? 0) : 0;
    }

    /**
     * Set the level of a skill.
     * The level specified can be 3 or 5 level-range.
     * Using the category the level is always converted to a 5 level-range internally.
     * 
     * Note:
     *  if level = 0,  the skill is considered as without level
     *  if level = -1, the skill is considered as disabled
     *      
     *  level = 1, 2, 3, 4, 5   =>  skill has a level specified
     *  level = 0               =>  skill has no level specified (same as if no level attribute)
     *  level = -1              =>  skill is disable (its parent has a level, so all children are disabled)
     * 
     */
    const setSkillLevel = (category, skill, level) => {
        
        // Adjust to 5 level-range
        const adjustedLevel = isDeclarative(category) && category.levels < 5 ? level + 1 : level;

        // Get skill's children
        const children = TreeUtil.toList(skill);

        // If we are changing the level
        if (adjustedLevel !== skill.level) {
            children.forEach(child => child.level = -1); // Disable all children
            skill.level = adjustedLevel; // Done after resetting to -1 (skill is part of the children list)
        }

        // If we are clearing current level
        else {
           children.forEach(child => delete child.level); // Reset level for all children
           delete skill.level;
        }
    }
    
    /**
     * Update the level of all children of a skill.
     */
    const setChildrenLevel = (skilltree, skill, level) => {
        const node = TreeUtil.findById(skilltree, skill.id);
        node.children?.forEach(child => child.level = level);
    }

    const toogleSelection = (skilltree, skill) => {
        const node = TreeUtil.findById(skilltree, skill.id);
        node.state = { ...node.state, selected: !node.state.selected };
        if (!node.state.selected) setChildrenLevel(skilltree, skill, null);
    }

    /**
     * Merge the category into the survey skilltree
     */
    const mergeCategory = (catalog, category) => {
        const categoryNode = TreeUtil.findById(catalog, category.id);
        categoryNode.children = category.children ?? [];
    }

    return {
        isRoot,
        isNotRoot,
        isDisabled,
        isCategory,
        isLeaf,
        hasLevel,
        isDeclarative,
        isNormalized,
        isLanguage,
        isGeneralization,
        isComposition,
        getSkillLevel,
        getSkillExpectedLevel,
        setSkillLevel,
        setChildrenLevel,
        toogleSelection,
        mergeCategory,
    }
}

export default CatalogLogic();

