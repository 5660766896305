
import { useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import useModalEffect from "../../../hooks/useModalEffect.js";
import useUserController from '../../../controllers/useUserController.js';
import useUserTypeController from '../../../controllers/useUserTypeController.js';

const InvitationCreate = ({ id, onCreate }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const modalRef = useRef();
    const [user, setUser] = useState({});
    const [userTypes, setUserTypes] = useState([]);
    const genders = t('common.genders');
    const languages = t('common.languages');

    const userController = useUserController();
    const userTypeController = useUserTypeController();
    
    /**
     * Page lifecycle
     */

    useModalEffect(modalRef, async () => {

        const user = await userController.create();
        setUser(user);

        const userTypes = await userTypeController.getUserTypes();
        setUserTypes(userTypes.items);
})

    /**
     * Event handlers
     */

    const handleChange = (attrs) => {
        setUser({ ...user, ...attrs });
    }
    
    const handleLanguageChange = (e) => {
        user.language = e.target.value === '' ? null : e.target.value;
        setUser({...user});
    }

    const handleUserTypeChange = (e) => {
        user.userType = userTypes.find(c => c.id === e.target.value);
        setUser({...user});
    }

    const handleSave = () => {
        onCreate(user);
    }

    return (

        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('invitations.create')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>
                        <div className='container pb-3'>

                            <div className='row'>
                                <div className='col form-group'>
                                    <label htmlFor='email'>{t('users.email')}</label>
                                    <input id='email' type='text' className='form-control' value={user.email ?? ''} placeholder={t('users.email-placeholder')} onChange={(e) => handleChange({ email: e.target.value })} />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col form-group'>
                                    <label htmlFor='email'>{t('users.password')}</label>
                                    <input id='password' type='text' className='form-control' value={user.password ?? ''} placeholder={t('users.password-placeholder')} onChange={(e) => handleChange({ password: e.target.value })} />
                                </div>
                            </div>

                            <div className='row pt-2'>

                                <div className='col form-group'>
                                    <label htmlFor='firstName'>{t('users.firstname')}</label>
                                    <input id='firstName' type='text' className='form-control' value={user.firstname ?? ''} placeholder={t('users.firstname-placeholder')} onChange={(e) => handleChange({ firstname: e.target.value })} />
                                </div>

                                <div className='col form-group'>
                                    <label htmlFor='lastName'>{t('users.lastname')}</label>
                                    <input id='lastName' type='text' className='form-control' value={user.lastname ?? ''} placeholder={t('users.lastname-placeholder')} onChange={(e) => handleChange({ lastname: e.target.value })} />
                                </div>

                            </div>

                            <div className='row pt-2'>

                                <div className='col form-group'>
                                    <label htmlFor='gender'>{t('users.gender')}</label>
                                    <select id='gender' className='form-select' value={user.gender ?? ''} onChange={(e) => handleChange({ gender: e.target.value })}>
                                        <option value={null}>{t('glossary.none')}</option>
                                        {genders.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                    </select>
                                </div>

                                <div className='col form-group'>
                                    <label htmlFor='lang'>{t('users.language')}</label>
                                    <select id='lang' className='form-select' value={user.language ?? ''} onChange={handleLanguageChange}>
                                        <option value={''}>{t('glossary.none')}</option>
                                        {languages?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                    </select>
                                </div>

                            </div>

                            <div className='row pt-2'>

                                <div className='col form-group'>
                                    <label htmlFor='type'>{t('glossary.usertype')}</label>
                                    <select id='type' className='form-select' value={user.userType?.id ?? '1'} onChange={handleUserTypeChange}>
                                        {userTypes?.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                                    </select>
                                </div>

                            </div>

                        </div>

                        <div className='modal-footer'>
                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' onClick={handleSave}>{t('button.create')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div >
    )
}

export default InvitationCreate;
