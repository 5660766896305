
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useModalEffect from '../../../hooks/useModalEffect.js';
import useUserController from '../../../controllers/useUserController.js';

import TabMain from './TabMain.js';
import TabWork from './TabWork.js';
import TabAddress from './TabAddress.js';
import TabRights from './TabRights.js';

const UserDetail = ({ id, user: theUser, showCompany = true, onSave }) => {

    /**
     * Page state
     */

    const modalRef = useRef();
    const { t } = useTranslation();
    const [user, setUser] = useState({});
    const [deletable, setDeletable] = useState(false);
    const userController = useUserController();

    /**
     * Page lifecycle
     */
   
    useModalEffect(modalRef, async () => {

        setUser(theUser);

        const deletable = await userController.deletable(theUser);
        setDeletable(deletable);
    })

    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setUser({ ...user, ...attributes });
    }

    return (

        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.user')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>
                        <div className='container'>

                            <ul className='nav nav-tabs'>
                                <li>
                                    <button className='nav-link active' data-bs-toggle='tab' data-bs-target='#tab1' type='button'>{t('users.tabmain')}</button>
                                </li>
                                <li>
                                    <button className='nav-link' data-bs-toggle='tab' data-bs-target='#tab2' type='button'>{t('users.tabwork')}</button>
                                </li>
                                <li>
                                    <button className='nav-link' data-bs-toggle='tab' data-bs-target='#tab3' type='button'>{t('users.tabaddress')}</button>
                                </li>
                                <li>
                                    <button className='nav-link' data-bs-toggle='tab' data-bs-target='#tab4' type='button'>{t('users.tabrights')}</button>
                                </li>
                            </ul>

                            <div className='tab-content py-4'>

                                <div id='tab1' className='tab-pane fade show active'>
                                    <TabMain user={user} onChange={handleChange} />
                                </div>

                                <div id='tab2' className='tab-pane fade'>
                                    <TabWork user={user} showCompany={showCompany} onChange={handleChange} />
                                </div>

                                <div id='tab3' className='tab-pane fade'>
                                    <TabAddress user={user} onChange={handleChange} />
                                </div>
                                
                                <div id='tab4' className='tab-pane fade'>
                                    <TabRights user={user} showCompany={showCompany} onChange={handleChange} />
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>

                            <div>
                                <button type='button' className='btn btn-light' data-bs-dismiss='modal' data-bs-toggle='modal' data-bs-target='#delete' disabled={!deletable}>{t('button.delete')}</button>
                            </div>

                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => onSave(user)}>{t('button.save')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default UserDetail;
