
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/postypes`;

const PositionTypeService = () => {

    const getPositionTypes = async (company, filter = null) => {

        let query = company || filter ? '?' : '';
        if (company) query += 'company=' + company.id + (filter ? '&' : '');

        if (filter) {
            const sort = filter.sort.map(item => item.name + ':' + item.sort).join(',');
            query += `offset=${filter.offset ?? ''}&limit=${filter.limit ?? ''}&text=${filter.text ?? ''}&sort=${sort}`;
        }

        return (await axios.get(`${SERVER_URL}${query}`)).data.payload;
    }

    const getPositionType = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const create = async (company) => {
        return (await axios.get(`${SERVER_URL}/create?company=${company.id}`)).data.payload;
    }

    const isDeletable = async (positionType) => {
        return (await axios.get(`${SERVER_URL}/${positionType.id}/deletable`)).data.payload;
    }

    const save = async (positionType) => {

        return positionType.id
            ? (await axios.put(`${SERVER_URL}/${positionType.id}`, positionType)).data.payload
            : (await axios.post(`${SERVER_URL}`, positionType)).data.payload;
    }

    const remove = async (positionType) => {
        await axios.delete(`${SERVER_URL}/${positionType.id}`);
    }

    return {
        getPositionTypes,
        getPositionType,
        create,
        isDeletable,
        save,
        remove
    }
}

export default PositionTypeService();