
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

const CountryDelete = ({ id, country, onDelete }) => {

    const { t } = useTranslation();

    return (
        <div id={id} className='modal fade'>
            <div className='modal-dialog modal-dialog-centered '>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.delete')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className="modal-body">
                        <p>{parse(t('common.delete-confirmation', { name: country.name }))}</p>
                    </div>

                    <div className='modal-footer'>
                        <div className='btn-group'>
                            <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => onDelete(country)}>{t('button.delete')}</button>
                            <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CountryDelete;
