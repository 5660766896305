
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import DiplomaTypeService from '../services/DiplomaTypeService.js';
import LangUtil from "../utils/LangUtil.js";

const useDiplomaTypeController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);

    /**
     * Functionalities
     */

    const getDiplomaTypes = async (filter = null) => {

        try {
            setLoaded(false);
            const data = await DiplomaTypeService.getDiplomaTypes(filter);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }    

    const getDiplomaType = async (id) => {
        if (id == null || id.trim().length === 0) return null;
        const diplomaType = await DiplomaTypeService.getDiplomaType(id);
        translate(diplomaType, auth.lang);
        return diplomaType;
    }    

    const deletable = async (diplomaType) => {
        return diplomaType.id != null && await DiplomaTypeService.isDeletable(diplomaType);
    }

    const create = async () => {
        const diplomaType = await DiplomaTypeService.create();
        translate(diplomaType);
        return diplomaType;
    }

    const save = async (diplomaType) => {

        try {
            untranslate(diplomaType);
            await DiplomaTypeService.save(diplomaType);
        }
        finally {
            translate(diplomaType);
        }
    }

    const remove = async (diplomaType) => {
        await DiplomaTypeService.remove(diplomaType);
    }

    const translate = (diplomaType) => {
        LangUtil.translate(diplomaType, 'name', auth.lang);
    }

    const untranslate = (diplomaType) => {
        LangUtil.untranslate(diplomaType, 'name');
    }

    const retranslate = (diplomaTypes) => {

        diplomaTypes.forEach(diplomaType => {
            untranslate(diplomaType);
            translate(diplomaType);
        })
    }

    return {
        loaded,
        getDiplomaType,
        getDiplomaTypes,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useDiplomaTypeController;