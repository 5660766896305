
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useUserTypeController from '../../../controllers/useUserTypeController.js';
import useModalEffect from '../../../hooks/useModalEffect.js';

const UserTypeDetail = ({ id, usertype: theUserType, onSave }) => {

    /**
     * Page state
     */

    const modalRef = useRef();
    const { t } = useTranslation();
    const [userType, setUserType] = useState({});
    const [deletable, setDeletable] = useState(false);
    
    const userTypeController = useUserTypeController(null, false);

    /**
     * Page lifecycle
     */
   
    useModalEffect(modalRef, async () => { 

        setUserType(theUserType);

        const deletable = await userTypeController.deletable(theUserType);
        setDeletable(deletable);
    })

    /**
     * Event handlers
     */

    const handleChange = (attributes) => {
        setUserType({ ...userType, ...attributes });
    }

    const handleSave = (usertype) => {
        onSave && onSave(usertype);
    }

    return (
        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('glossary.usertype')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className='modal-body'>

                        <div className='col-6 form-group'>
                            <label htmlFor='code'>{t('usertypes.code')}</label>
                            <input id='code' type='text' className='form-control' value={userType.code ?? ''} placeholder={t('usertypes.code-placeholder')} onChange={(e) => handleChange({ code: e.target.value })} />
                        </div>

                        <div className='col-6 form-group'>
                            <label htmlFor='name'>{t('usertypes.name')}</label>
                            <input id='name' type='text' className='form-control' value={userType.name ?? ''} placeholder={t('usertypes.name-placeholder')} onChange={(e) => handleChange({ name: e.target.value })} />
                        </div>

                        <div className='col form-group'>
                            <label htmlFor='description'>{t('usertypes.description')}</label>
                            <input id='description' type='text' className='form-control' value={userType.description ?? ''} placeholder={t('usertypes.description-placeholder')} onChange={(e) => handleChange({ description: e.target.value })} />
                        </div>

                    </div>

                    <div className='modal-footer'>
                        <div className='d-flex justify-content-between w-100'>

                            <div>
                                <button type='button' className='btn btn-light' data-bs-dismiss='modal' data-bs-toggle='modal' data-bs-target='#delete' disabled={!deletable}>{t('button.delete')}</button>
                            </div>

                            <div className='btn-group'>
                                <button className='btn btn-light' data-bs-dismiss='modal' onClick={() => handleSave(userType)}>{t('button.save')}</button>
                                <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default UserTypeDetail;
