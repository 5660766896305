
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import useUserController from '../controllers/useUserController.js';
import useDiplomaTypeController from '../controllers/useDiplomaTypeController.js';
import FormationService from '../services/FormationService.js';

const useFormationController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);

    const userController = useUserController();
    const diplomaTypeController = useDiplomaTypeController();

    /**
     * Functionalities
     */

    const getFormations = async (filter = null) => {

        try {
            setLoaded(false);
            const data = await FormationService.getFormations(auth.user, filter);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const getFormation = async (id) => {
        if (id == null || id.trim().length === 0) return null;
        const formation = await FormationService.getFormation(id);
        translate(formation, auth.lang);
        return formation;
    }

    const deletable = async (formation) => {
        return formation.id != null && await FormationService.isDeletable(formation);
    }

    const create = async (user) => {
        const formation = await FormationService.create(user);
        translate(formation);
        return formation;
    }

    const save = async (formation) => {

        try {
            untranslate(formation);
            await FormationService.save(formation);
        }
        finally {
            translate(formation);
        }
    }

    const remove = async (formation) => {
        await FormationService.remove(formation);
    }

    const translate = (formation) => {
        formation.user && userController.translate(formation.user);
        formation.diplomaType && diplomaTypeController.translate(formation.diplomaType);
    }

    const untranslate = (formation) => {
        formation.user && userController.untranslate(formation.user);
        formation.diplomaType && diplomaTypeController.untranslate(formation.diplomaType);
    }

    const retranslate = (formations) => {

        formations.forEach(formation => {
            untranslate(formation);
            translate(formation);
        })
    }

    return {
        loaded,
        getFormation,
        getFormations,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useFormationController;