
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useDiplomaTypeController from '../../../controllers/useDiplomaTypeController.js';

import AppLogic from '../../../logic/AppLogic.js';
import ReactUtil from '../../../utils/ReactUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import DiplomaTypeDetail from './DiplomaTypeDetail.js';
import DiplomaTypeDelete from './DiplomaTypeDelete.js';
import Paginator from '../../../components/filter/Paginator.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const DiplomaTypes = () => {


    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [filter, setFilter] = useState({ offset: 0, limit: AppLogic.pageLimit, sort: [] });
    const [diplomaType, setDiplomaType] = useState({});
    const [diplomaTypes, setDiplomaTypes] = useState([]);

    const diplomaTypeController = useDiplomaTypeController();

    const columns = {
        code: { property: 'code', display: 'diplomatypes.code' },
        name: { property: 'name', display: 'diplomatypes.name' }
    }

    /**
     * Page lifecycle
     */

    const reload = async (filter) => {
        const data = await diplomaTypeController.getDiplomaTypes(filter);
        setDiplomaTypes(data.items);
        setFilter(data.filter);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [])

    // eslint-disable-next-line 
    useEffect(() => { diplomaTypeController.retranslate(diplomaTypes); setDiplomaTypes([...diplomaTypes]); }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const diplomaType = await diplomaTypeController.create();
        setDiplomaType(diplomaType);
        ReactUtil.show('detail');
    }

    const handleDetail = async (id) => {
        const diplomaType = await diplomaTypeController.getDiplomaType(id);
        setDiplomaType(diplomaType);
        ReactUtil.show('detail');
    }

    const handleSave = async (diplomaType) => {
        await diplomaTypeController.save(diplomaType);
        reload(filter);
    }

    const handleDelete = async (diplomaType) => {
        await diplomaTypeController.remove(diplomaType);
        reload(filter);
    }

    const handleFilter = (filter) => {
        reload(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!diplomaTypeController.loaded} />
            <DiplomaTypeDetail id='detail' diplomaType={diplomaType} onSave={handleSave} />
            <DiplomaTypeDelete id='delete' diplomaType={diplomaType} onDelete={handleDelete} />

            <div className='row p-4'>

                <TitleBar title={t('glossary.diplomatypes')} filter={filter} onChange={handleFilter} />

                <hr />


                <table className='table table-hover'>
                    <thead>
                        <tr>


                            <TableHeader descriptor={columns.code} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.name} filter={filter} onChange={handleFilter} />

                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            diplomaTypes.map((diplomaType) => (
                                <tr key={diplomaType.id} className='align-middle'>

                                    <td>{diplomaType.code}</td>
                                    <td>{diplomaType.name}</td>

                                    <td className='text-center'>
                                        <button className='btn btn-light' onClick={() => handleDetail(diplomaType.id)}>
                                            <img src={edit_img} alt='edit' />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default DiplomaTypes
