
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useMotionController from '../../../controllers/useMotionController.js';
import useUserController from '../../../controllers/useUserController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import WishesView from '../../../components/radar/WishesView.js';
import AxesView from '../../../components/radar/AxesView.js';
import RadarView from '../../../components/radar/RadarView.js';
import OpportunityView from './OpportunityView.js';

import save_img from '../../../assets/images/save.svg';
import reset_icon from '../../../assets/images/reset.svg';
import axes_icon_img from '../../../assets/images/axes.svg';

import './Opportunities.css';

const Opportunities = () => {

    /**
     * Page state
     */

    const { auth } = useAuthContext();
    const { t } = useTranslation();

    const [user, setUser] = useState({});
    const [wishes, setWishes] = useState([]);
    const [axes, setAxes] = useState([]);
    const [motion, setMotion] = useState({});
    const [matchings, setMatchings] = useState([]);
    const [labels, setLabels] = useState({});
    const [resetRadar, setRadarReset] = useState();
    const [loaded, setLoaded] = useState(false);
    const [showProfile, setShowProfile] = useState(false);

    const motionController = useMotionController();
    const userController = useUserController();

    /**
     * Page lifecycle
     */

    // If axes change, refresh axes label
    useEffect(() => {

        const xaxis = user.categories?.find(w => w.id === axes.x);
        const yaxis = user.categories?.find(w => w.id === axes.y);

        setLabels({
            horizontal: xaxis ? xaxis.label : 'n/a',
            vertical: yaxis ? yaxis.label : 'n/a'
        })

        // eslint-disable-next-line
    }, [axes, user])

    // Each time lang changed, get initial user, wishes and axes
    useEffect(() => { 
    
        (async () => {

            try {

                setLoaded(false);

                // Get the user fully initialize from motion controller
                const user = await motionController.getMotionUser(auth.user.id);
    
                // Update view state
                setAxes(user.axes);
                setWishes(user.wishes);
                setUser(user);
            }
            catch (e) {
                console.log(e);
            }            
            finally {
                setLoaded(true);
            }

        })()

        // eslint-disable-next-line
    }, [auth.lang]);

    // If wishes change, refresh matchihngs
    useEffect(() => {

        (async () => {

            try {
                setLoaded(false);

                const matchings = await motionController.getMatchingsForUser(user, wishes);
                setMatchings(matchings);
            }
            catch (e) {
                console.log(e);
            }
            finally {
                setLoaded(true);
            }

        })();

        // eslint-disable-next-line
    }, [wishes]);

    /**
     * Event handlers
     */

    const handleRadarInit = (radarReset) => {
        setRadarReset(() => radarReset); // useState cannot store function directly
    }

    const handleReset = () => {
        resetRadar && resetRadar(); // radar reset scale + position
    }

    const handleWishesChange = ({ wishes }) => {
        setWishes(wishes);
    }

    const handleAxesChange = (axes) => {
        setAxes(axes);
    }

    const handleShowProfile = (motion) => {
        setMotion(motion);
        setShowProfile(true);
    }

    const handleHideProfile = () => {
        setShowProfile(false);
    }

    const handleSave = () => {
        setShowProfile(false);
        userController.save({ ...user, wishes, axes });
    }

    const handleLike = () => {
        setShowProfile(false);
        motionController.like(user, motion);
    }

    return (

        <div className='container-fluid'>
            <div className='row p-4'>

                <LoadingIcon loading={!loaded} />
                <AxesView id='axes' axes={axes} categories={user.categories} onChange={handleAxesChange} />
                <OpportunityView show={showProfile} motion={motion} onLike={handleLike} onClose={handleHideProfile} />

                <div className='d-flex align-items-center'>
                    <h5 className='text-muted mb-1'>{t('profile.opportunities')}</h5>
                </div>

                <hr />

                <div id='opportunities' className='d-flex'>

                    <div id='wishes' className={'border rounded p-2 me-3 collapse collapse-horizontal show'}>
                        <WishesView wishes={wishes} onChange={handleWishesChange} />
                    </div>

                    <div className='border rounded p-2 flex-fill'>

                        <div id='opportunities-toolbar' className='btn-group'>
                            <button className='btn btn-light' onClick={handleSave}><img src={save_img} alt='save' /></button>
                            <button className='btn btn-light' onClick={handleReset}><img src={reset_icon} alt='reset' /></button>
                            <button className='btn btn-light' data-bs-toggle='modal' data-bs-target='#axes'><img src={axes_icon_img} alt='axes' /></button>
                        </div>

                        <RadarView entity={user} axes={axes} labels={labels} matchings={matchings} onInit={handleRadarInit} onSelect={handleShowProfile} />

                    </div>

                </div>

            </div>
        </div>
    )
}

export default Opportunities;
