
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import AssessmentService from '../services/AssessmentService.js';

export const AssessmentStatus = { CREATED: 0, STARTED: 1, COMPLETED: 2 };

const useAssessmentController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);

    /**
     * Functionalities
     */

    const getAssessments = async (filter = null) => {

        try {
            setLoaded(false);
            const data = await AssessmentService.getAssessments(auth.user, filter);
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const getAssessment = async (id) => {
        if (id == null || id.trim().length === 0) return null;
        return await AssessmentService.getAssessment(id);
    }

    const deletable = async (assessment) => {
        return assessment.id != null && await AssessmentService.isDeletable(assessment);
    }

    const create = async (user) => {
        return await AssessmentService.create(user);
    }

    const save = async (assessment) => {
        await AssessmentService.save(assessment);
    }

    const remove = async (assessment) => {
        await AssessmentService.remove(assessment);
    }

    const start = async (assessment) => {
        const link = document.createElement('a');
        assessment.id = await AssessmentService.save(assessment);
        link.href = await AssessmentService.start(assessment);
        link.click();
    }

    const resume = async (assessment) => {
        const link = document.createElement('a');
        link.href = await AssessmentService.resume(assessment);
        link.click();
    }

    const report = async (assessment) => {
        const link = document.createElement('a');
        link.href = await AssessmentService.report(assessment);
        link.click();
    }

    return {
        loaded,
        getAssessment,
        getAssessments,
        deletable,
        create,
        save,
        remove,
        start,
        resume,
        report
    }
}

export default useAssessmentController;