
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useSurveyController from '../../../controllers/useSurveyController.js';

import AppLogic from '../../../logic/AppLogic.js';
import SurveyLogic from '../../../logic/SurveyLogic.js';
import DateUtil from '../../../utils/DateUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import Paginator from '../../../components/filter/Paginator.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';
import chart_img from '../../../assets/images/chart.svg';

const Surveys = () => {

    /**
     * Page state
    */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const navigate = useNavigate();
    const [filter, setFilter] = useState({ offset: 0, limit: AppLogic.pageLimit, sort: [] });
    const [surveys, setSurveys] = useState([]);

    const surveyController = useSurveyController(filter);

    const columns = {
        creation: { property: 'creation', display: 'surveys.date' },
        duedate: { property: 'dueDate', display: 'surveys.duedate' },
        name: { property: 'name', display: 'surveys.name' },
        owner: { property: 'ownerLastname', display: 'surveys.owner' },
        status: { property: 'status', display: 'surveys.status' }
    }

    /**
     * Page lifecycle
     */

    const reload = async (filter) => {
        const data = await surveyController.getSurveys(filter);
        setSurveys(data.items);
        setFilter(data.filter);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [auth.company])

    // eslint-disable-next-line 
    useEffect(() => { surveyController.retranslate(surveys); setSurveys([...surveys]); }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        navigate('/manager/surveys/new');
    }

    const handleDetail = (id) => {
        navigate(`/manager/surveys/${id}`);
    }
    
    const handleChart = (id) => {
        navigate(`/manager/surveys/${id}/charts`);
    }

    const handleFilter = (filter) => {
        reload(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!surveyController.loaded} />

            <div className='row p-4'>

                <TitleBar title={t('glossary.surveys')} filter={filter} onChange={handleFilter} />

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>

                            <TableHeader descriptor={columns.creation} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.name} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.owner} filter={filter} onChange={handleFilter} />
                            <th className='text-center'>{t('glossary.invitations')}</th>
                            <TableHeader descriptor={columns.status} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.duedate} filter={filter} onChange={handleFilter} />

                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            surveys.map((survey) => (
                                <tr key={survey.id} className='align-middle'>

                                    <td>{DateUtil.toLocaleString(survey.creation)}</td>
                                    <td>{survey.name}</td>
                                    <td>{survey.owner?.firstname + ' ' + survey.owner?.lastname}</td>
                                    <td className='text-center'>{survey.invitationCount > 0 ? survey.invitationCount : '-'}</td>
                                    <td>{SurveyLogic.toStringStatus(survey.status)}</td>
                                    <td>{DateUtil.toLocaleString(survey.dueDate)}</td>
                                    
                                    <td className='text-center'>
                                        <div className='btn-group'>
                                            <button className='btn btn-light' onClick={() => handleDetail(survey.id)}>
                                                <img src={edit_img} alt='edit' />
                                            </button>
                                            <button className='btn btn-light' onClick={() => handleChart(survey.id)}>
                                                <img src={chart_img} alt='chart' />
                                            </button>
                                        </div>
                                    </td>
                                    
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default Surveys
