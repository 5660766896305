
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import WishService from '../services/WishService.js';
import LangUtil from "../utils/LangUtil.js";
import TreeUtil from '../utils/TreeUtil.js';

const useWishController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);

    /**
     * Functionalities
     */

    const getWishes = async (filter = null) => {

        try {
            setLoaded(false);
            const data = await WishService.getWishes(filter);
            data.items.sort((a, b) => a.order - b.order);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const getWish = async (id) => {
        if (id == null || id.trim().length === 0) return null;
        const wish = await WishService.getWish(id);
        translate(wish, auth.lang);
        return wish;
    }    

    const getWishCategories = async (filter) => {
        const data = await getWishes(filter);
        data.items = data.items.filter(wish => wish.parent == null)
        return data;
    }    

    const getWishTypes = async () => {

        try {
            setLoaded(false);
            const data = await WishService.getWishTypes();
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const deletable = async (wish) => {
        return wish.id != null && await WishService.isDeletable(wish);
    }

    const create = async () => {
        const wish = await WishService.create();
        translate(wish, auth.lang);
        return wish;
    }

    const save = async (wish) => {

        try {
            untranslate(wish);
            await WishService.save(wish);
        }
        finally {
            translate(wish, auth.lang);
        }
    }

    const remove = async (wish) => {
        await WishService.remove(wish);
    }

    const translate = (wish) => {
        LangUtil.translate(wish, 'name', auth.lang);
        LangUtil.translate(wish.type, 'name', auth.lang);
        wish.children?.forEach(w => translate(w));
    }

    const untranslate = (wish) => {
        LangUtil.untranslate(wish, 'name');
        LangUtil.untranslate(wish.type, 'name');
        wish.children?.forEach(w => untranslate(w));
    }

    const retranslate = (wishes) => {

        wishes.forEach(wish => {
            untranslate(wish);
            translate(wish);
        })
    }

    const retranslateTree = (wishTree) => {

        TreeUtil.toList(wishTree).forEach(wish => {
            untranslate(wish);
            translate(wish);
        })
    }

    return {
        loaded,
        getWish,
        getWishes,
        getWishTypes,
        getWishCategories,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate,
        retranslateTree
    }
}

export default useWishController;