
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useWishController from '../../../controllers/useWishController.js';

import AppLogic from '../../../logic/AppLogic.js';
import ReactUtil from '../../../utils/ReactUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import Paginator from '../../../components/filter/Paginator.js';
import WishDetail from './WishDetail.js';
import WishDelete from './WishDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Wishes = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [filter, setFilter] = useState({ offset: 0, limit: AppLogic.pageLimit, sort: [] });
    const [wish, setWish] = useState({});
    const [wishes, setWishes] = useState([]);

    const wishController = useWishController();

    const columns = {
        name: { property: 'code', display: 'wishes.name' },
        category: { property: 'category', display: 'wishes.category' },
        order: { property: 'order', display: 'wishes.order' },
        type: { property: 'type', display: 'wishes.type' }
    }

    /**
     * Page lifecycle
     */

    const reload = async (filter) => {
        const data = await wishController.getWishes(filter);
        setWishes(data.items);
        setFilter(data.filter);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [])

    // eslint-disable-next-line 
    useEffect(() => { wishController.retranslate(wishes); setWishes([...wishes]); }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        const wish = await wishController.create();
        setWish(wish);
        ReactUtil.show('detail');
    }

    const handleDetail = async (id) => {
        const wish = await wishController.getWish(id);
        setWish(wish);
        ReactUtil.show('detail');
    }

    const handleSave = async (wish) => {
        await wishController.save(wish);
        reload(filter);
    }

    const handleDelete = async (wish) => {
        await wishController.remove(wish);
        reload(filter);
    }

    const handleFilter = (filter) => {
        reload(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!wishController.loaded} />
            <WishDetail id='detail' wish={wish} onSave={handleSave} />
            <WishDelete id='delete' wish={wish} onDelete={handleDelete} />

            <div className='row p-4'>

                <TitleBar title={t('glossary.wishes')} filter={filter} onChange={handleFilter} />

                <hr />


                <table className='table table-hover'>
                    <thead>
                        <tr>

                            <TableHeader descriptor={columns.name} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.category} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.order} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.type} filter={filter} onChange={handleFilter} />

                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            wishes.map((wish, i) => {

                                const wishCategory = wishes.find(w => w.id === wish.parent);

                                return (
                                    <tr key={i} className='align-middle'>

                                        <td>{wish.name}</td>
                                        <td>{wishCategory?.name ?? '-'}</td>
                                        <td>{wish.order}</td>
                                        <td>{wish.type?.name ?? '-'}</td>

                                        <td className='text-center'>
                                            <button className='btn btn-light' onClick={() => handleDetail(wish.id)}>
                                                <img src={edit_img} alt='edit' />
                                            </button>
                                        </td>
                                        
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default Wishes
