
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useMotionController from '../../../controllers/useMotionController.js';

import AppLogic from '../../../logic/AppLogic.js';
import DateUtil from '../../../utils/DateUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import Paginator from '../../../components/filter/Paginator.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';

const Motions = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const navigate = useNavigate();
    const [filter, setFilter] = useState({ offset: 0, limit: AppLogic.pageLimit, sort: [] });
    const [motions, setMotions] = useState([]);

    const motionController = useMotionController();

    const columns = {
        date: { property: 'creation', display: 'motions.date' },
        name: { property: 'name', display: 'motions.name' },
        owner: { property: 'ownerLastname', display: 'motions.owner' }
    }

    /**
     * Page lifecycle
     */

    const reload = async (filter) => {
        const data = await motionController.getMotions(filter);
        setMotions(data.items);
        setFilter(data.filter);
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [auth.company])

    // eslint-disable-next-line 
    useEffect(() => { motionController.retranslate(motions); setMotions([...motions]); }, [auth.lang]);

    /**
     * Event handlers
     */

    const handleNew = async () => {
        navigate('/manager/motions/new');
    }

    const handleDetail = (id) => {
        navigate('/manager/motions/' + id);
    }

    const handleFilter = (filter) => {
        reload(filter);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!motionController.loaded} />

            <div className='row p-4'>

                <TitleBar title={t('glossary.motions')} filter={filter} onChange={handleFilter} />

                <hr />

                <table className='table table-hover'>
                    <thead>
                        <tr>

                            <TableHeader descriptor={columns.date} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.name} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.owner} filter={filter} onChange={handleFilter} />

                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            motions.map((motion) => (
                                <tr key={motion.id} className='align-middle'>

                                    <td>{DateUtil.toLocaleString(motion.creation)}</td>
                                    <td>{motion.name}</td>
                                    <td>{motion.owner?.firstname + ' ' + motion.owner?.lastname}</td>

                                    <td className='text-center'>
                                        <div className='btn-group'>
                                            <button className='btn btn-light' onClick={() => handleDetail(motion.id)}>
                                                <img src={edit_img} alt='edit' />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default Motions
