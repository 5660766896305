
import { useTranslation } from 'react-i18next';
import Slider from '../selector/Slider.js';
import CatalogLogic from '../../logic/CatalogLogic.js';
import StringUtil from '../../utils/StringUtil.js';

import './NormalizedView.css';

const NormalizedViewEditable = ({ category, disabled = false, reviews = false, onChange }) => {

    /**
     * Page state
     */
    const { t } = useTranslation();
    const labels = t('common.normalized-view-labels');

    /**
     * Event handlers
     */

    const handleLevelChange = (skill, level) => {

        // Change the skill's level
        CatalogLogic.setSkillLevel(category, skill, level);

        // And propagates the category change to the owner
        onChange({ ...category });
    }

    return category && category.children?.map(skill => {

        return (
            <div key={skill.id} className='container border mb-3'>
                abcd
                <table className='table table-normalized'>
                    <thead>
                        <tr>
                            <th className='text-start'>
                                {skill.label}
                            </th>
                            <th>
                                <div className='table-cell'>
                                    {labels.map((label, i) => <div key={i}>{label}</div>)}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            skill.children?.map((skill, i) => {

                                const level = CatalogLogic.getSkillLevel(category, skill);
                                const hasTooltip = !StringUtil.isEmpty(skill.description);

                                return (
                                    <tr key={skill.id}>
                                        <td className='text-start ps-4'>
                                            {
                                                hasTooltip &&
                                                <div data-bs-toggle='tooltip' data-bs-title={skill.description} data-bs-placement='top' data-bs-html='true'>{skill.label}</div>
                                            }
                                            {
                                                !hasTooltip &&
                                                skill.label
                                            }
                                        </td>
                                        <td style={{ paddingLeft: '10%', paddingRight: '3%' }}>
                                            {
                                                reviews
                                                    ? <Slider min='1' max='4' value={level} disabled={disabled} userLevel={skill.userLevel} reviews={skill.reviews} onChange={(e) => handleLevelChange(skill, e.target.value)} />
                                                    : <Slider min='1' max='4' value={level} disabled={disabled} onChange={(e) => handleLevelChange(skill, e.target.value)} />
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        )
    })
}

export default NormalizedViewEditable;
