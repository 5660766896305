
import axios from "axios";
import AppLogic from '../logic/AppLogic.js';

const SERVER_URL = `${AppLogic.serverUrl}/api/experiences`;

const ExperienceService = () => {

    const getExperiences = async (user, filter = null) => {

        let query = user || filter ? '?' : '';
        if (user) query += 'user=' + user.id + (filter ? '&' : '');

        if (filter) {
            const sort = filter.sort.map(item => item.name + ':' + item.sort).join(',');
            query += `offset=${filter.offset ?? ''}&limit=${filter.limit ?? ''}&text=${filter.text ?? ''}&sort=${sort}`;
        }

        return (await axios.get(`${SERVER_URL}${query}`)).data.payload;
    }

    const getExperience = async (id) => {
        return (await axios.get(`${SERVER_URL}/${id}`)).data.payload;
    }

    const create = async (user) => {
        return (await axios.get(`${SERVER_URL}/create?user=${user.id}`)).data.payload;
    }

    const isDeletable = async (experience) => {
        return (await axios.get(`${SERVER_URL}/${experience.id}/deletable`)).data.payload;
    }

    const save = async (experience) => {

        return experience.id
            ? (await axios.put(`${SERVER_URL}/${experience.id}`, experience)).data.payload
            : (await axios.post(`${SERVER_URL}`, experience)).data.payload;
    }

    const remove = async (experience) => {
        await axios.delete(`${SERVER_URL}/${experience.id}`);
    }

    return {
        getExperiences,
        getExperience,
        create,
        isDeletable,
        save,
        remove
    }
}

export default ExperienceService();