
import { useAuthContext } from './useAuthContext.js';
import FileUtil from "../utils/FileUtil.js";
import ImportExportService from '../services/ImportExportService.js';

const useImportExportController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();

    const importDatabase = async (filename) => {

        // Load backup file (json format)
        const data = await FileUtil.readJson(filename);

        // Post the file content into the backend
        await ImportExportService.importDatabase(data);
    }

    const exportDatabase = async () => {

        // Get skillspot database content (in json format)
        const data = await ImportExportService.exportDatabase();

        // Create a href linked to the file content (blob)
        const link = document.createElement('a');
        const blob = new Blob([data], { type: 'application/json' });
        link.href = URL.createObjectURL(blob);
        link.download = 'skillspot-database.json';

        // Simulate a user click to autodownload the file
        link.click();
    }

    const importCompany = async (filename) => {

        // Load backup file (excel format)
        const data = await filename.arrayBuffer();

        // Post the file content into the backend
        await ImportExportService.importCompany(auth.company, data);
    }

    const exportCompany = async () => {

        // Get company content (in excel format)
        const data = await ImportExportService.exportCompany(auth.company);

        // Create an autodownload link
        const link = document.createElement('a');
        const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
        link.href = URL.createObjectURL(blob);
        link.download = `${auth.company.name}-database.xlsx`.toLowerCase();
        link.click();
    }

    return {
        importDatabase,
        exportDatabase,
        exportCompany,
        importCompany,
    }
}

export default useImportExportController;