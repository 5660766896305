
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useUserController from '../../../controllers/useUserController.js';

import AppLogic from '../../../logic/AppLogic.js';
import ReactUtil from '../../../utils/ReactUtil.js';
import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import TitleBar from '../../../components/filter/TitleBar.js';
import TableHeader from '../../../components/filter/TableHeader.js';
import DateUtil from '../../../utils/DateUtil.js';
import StringUtil from '../../../utils/StringUtil.js';
import Paginator from '../../../components/filter/Paginator.js';
import UserDetail from '../../admin/users/UserDetail.js';
import UserDelete from '../../admin/users/UserDelete.js';

import new_img from '../../../assets/images/plus.svg';
import edit_img from '../../../assets/images/pen.svg';
import cv_img from '../../../assets/images/cv.svg';
import hr360_img from '../../../assets/images/hr360.svg';

const Users = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [filter, setFilter] = useState({ offset: 0, limit: AppLogic.pageLimit, sort: [] });
    const [user, setUser] = useState({});
    const [users, setUsers] = useState([]);

    const navigate = useNavigate();
    const userController = useUserController();

    const columns = {
        email: { property: 'email', display: 'users.email' },
        firstname: { property: 'firstname', display: 'users.firstname' },
        lastname: { property: 'lastname', display: 'users.lastname' },
        roles: { property: 'roles', display: 'glossary.userrole' },
        usertype: { property: 'userType.name', display: 'glossary.usertype' },
        team: { property: 'team', display: 'users.team' },
        lastlogin: { property: 'lastlogin', display: 'users.lastlogin', sortable: false }
    }

    /**
     * Page lifecycle
     */

    const reload = async (filter) => {    
        const data = await userController.getUsers(auth.company, filter);
        setUsers(data.items);
        setFilter(data.filter); 
    }

    // eslint-disable-next-line 
    useEffect(() => { reload(filter) }, [auth.company])
    
    // eslint-disable-next-line 
    useEffect(() => { userController.retranslate(users); setUsers([...users]); }, [auth.lang]);
    
    /**
     * Event handlers
     */

    const handleNew = async () => {
        const user = await userController.create();
        setUser(user);
        ReactUtil.show('detail');
    }

    const handleDetail = async (id) => {
        const user = await userController.getUser(id);
        setUser(user);
        ReactUtil.show('detail');
    }

    const handleSave = async (user) => {
        await userController.save(user);
        reload(filter);
    }

    const handleDelete = async (user) => {
        await userController.remove(user);
        reload(filter);
    }

    const handleFilter = (filter) => {
        reload(filter);
    }

    const handleCurriculum = (user) => {
        navigate(`/user/cv/${user.id}`);
    }

    const handleReview = (user) => {
        navigate(`/manager/reviews/${user.id}`);
    }

    return (

        <div className='container'>

            <LoadingIcon loading={!userController.loaded} />
            <UserDetail id='detail' user={user} onSave={handleSave} />
            <UserDelete id='delete' user={user} onDelete={handleDelete} />

            <div className='row p-4'>

                <TitleBar title={t('glossary.users')} filter={filter} onChange={handleFilter} />

                <hr />
                
                <table className='table table-hover'>
                    <thead>
                        <tr>

                            <TableHeader descriptor={columns.email} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.firstname} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.lastname} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.roles} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.team} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.usertype} filter={filter} onChange={handleFilter} />
                            <TableHeader descriptor={columns.lastlogin} filter={filter} onChange={handleFilter} />

                            <th className='text-center'>
                                <button className='btn btn-light' onClick={handleNew}>
                                    <img src={new_img} alt='new' />
                                </button>
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.map((user) => (
                                <tr key={user.id} className={'align-middle' + (user.disabled ? ' text-muted' : '')}>
                                    
                                    <td>{user.email}</td>
                                    <td>{user.firstname}</td>
                                    <td>{user.lastname}</td>
                                    <td>{user.roles?.map(role => role.name).join(', ')}</td>
                                    <td>{StringUtil.isEmpty(user.team) ? '-' : user.team}</td>
                                    <td>{user.userType ? user.userType.name : '-'}</td>
                                    <td className='text-center'>{DateUtil.toLocaleString(user.lastlogin)}</td>
                                    
                                    <td className='text-center'>
                                        <div className='btn-group'>
                                            <button className='btn btn-light' onClick={() => handleDetail(user.id)}><img src={edit_img} alt='edit' /></button>
                                            <button className='btn btn-light' onClick={() => handleCurriculum(user)}><img src={cv_img} alt='curriculum' /></button>
                                            <button className='btn btn-light' onClick={() => handleReview(user)}><img src={hr360_img} alt='360' /></button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <Paginator filter={filter} onChange={handleFilter} />

            </div>
        </div>
    )
}

export default Users
